import React, { FormEvent, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import { toast } from "react-toastify";
import { Spinner } from "../../../../../components";
import { toastConfig } from "../../../../../utils/utils";
import { Catalog } from "../../../../../models/catalog-model";
import { deleteCatalog } from "../../../../../services/campagnes-service";

interface ImportCatalogProps {
  open: boolean;
  setOpen: () => void;
  setReload: (r: boolean) => void;
  catalog: Catalog;
}

export default function DeleteCatalogDialog({ open, setOpen, setReload, catalog }: ImportCatalogProps) {
  const [loading, setLoading] = React.useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setLoading(true)

    await deleteCatalog(catalog.id).then((res: boolean) => {
      if (res) {
        toast.success("Image Supprime avec success",toastConfig);
        setReload(true);
        setOpen()
      }
    }).catch((e) => {
      toast.error(e,toastConfig);
    }).finally(() => setLoading(false))
    
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500  focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={setOpen}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form action="" method="post" onSubmit={async (e) => handleSubmit(e)}>
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 px-4"
                      >
                        Supprimer un Article/Image
                      </Dialog.Title>
                      <hr className="my-4" />
                      <div className="flex flex-col gap-4 w-full px-4">
                        {/*  */}
                        <div className={"rounded-md bg-gray-50 p-4 shadow-md w-full"}>
                          <label
                            htmlFor=""
                            className={"flex flex-col py-10 items-center gap-2 cursor-pointer "}
                          >
                            <XCircleIcon className="text-red-600 h-12 w-12" />
                            <h5 className="text-sm font-semibold text-gray-600 text-center">
                              Etes Vous sur de vouloir Supprimer l'Article/Image: <b>{catalog?.description}</b>({catalog?.name})
                            </h5>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-8" />
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between px-4 "}>
                    <button
                      type="submit"
                      className={"inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto " + (loading? "hidden":"")}
                      disabled={loading}
                    >
                      Supprimer Image/Article
                    </button>
                    <button
                      type="button"
                      className={"mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto " + (loading? "hidden":"")}
                      onClick={setOpen}
                      disabled={loading}
                    >
                      Annuler
                    </button>
                  </div>
                  <div className={"mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-end px-4 "}>
                    <span className={!loading? "hidden":""}>
                      <Spinner size="sm" />
                    </span>
                  </div>
                  
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
