import classNames from "classnames";
import { useThemeContext } from "../core/context/theme-context";

export default function Footer() {
  const { color } = useThemeContext();
  return (
    <footer
      className={classNames(
        `${
          color === "green"
            ? "bg-green-900"
            : color === "purple"
            ? "bg-purple-900"
            : color === "blue"
            ? "bg-blue-900"
            : color === "brown"
            ? "bg-stone-900"
            : "bg-gray-900"
        }`,
        "fixed bottom-0 h-6 border w-full xl:pr-6 z-50  shadow-2xl"
      )}
    >
      <div className="relative flex justify-center items-center h-full">
        <span className="text-gray-300 text-[10px]">
          Coypright @2024. DigiAssur v1.0.25 - KuTiWa.{" "}
          <a href="#" className="hover:text-green-100">
            Conditions générale d'utilisation
          </a>
        </span>

        <span className="text-gray-300 text-[10px] absolute right-2">
          Vous êtes connecté à l'agence de{" "}
          <span className="font-extrabold text-xs">Douala</span>.
        </span>
      </div>
    </footer>
  );
}
