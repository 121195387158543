import classNames from "classnames";
import { UserChat } from "../core/models";

import UserAvatar from "./user-avatar";
import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import MenuList from "./menu-list";
import { Contact, WhatsappContact } from "../models/contact-model";
import { Chat } from "../models/message-model";
import React from "react";
import { getContactAvatar, getContactDetails, getContatsList } from "../services/chat-service";
import { getDateFromTimestamp } from "../utils/utils";

interface ChatContactListProps {
  contact: Contact;
  className?: string;
  setPhone: () => void;
  openChat: () => void;
}

export default function ChatContactList({ contact, openChat, setPhone, className }: ChatContactListProps) {

  const [avatar, setAvatar] = React.useState<string>("/assets/images/img-1.jpg");
  const [avatarFetcehd, setAvatarFetched] = React.useState<boolean>(false);
  
  React.useEffect(() => {

    if (!avatarFetcehd && typeof contact !== "undefined") {
      // let p = contact.phone
      // if (contact.phone.includes("@") || contact.phone.includes("@c.us")) {
      //   p += "@c.us";
      // }
      // console.log(p);
      // getContactAvatar(p).then((av) => {
      //   if (typeof av != "undefined") {
      //     setAvatar(av);
      //   } else {
      //     setAvatar("/assets/images/img-1.jpg")
      //   }
      //   setAvatarFetched(true);
      // });
    }
  }, [avatarFetcehd])
  function openUserChat() {
    setPhone();
    openChat();
  }

  return (
    <div
      onClick={openUserChat}
      className={classNames(
        className,

        "py-2 pl-3 flex justify-between items-center w-full"
      )}
    >
      <div className="flex items-center gap-x-4 min-w-full">
        <img className="rounded-md h-12 w-12" src={avatar} alt="" />
        <div className="flex flex-col gap-y-1 w-full">
          <div className="relative flex flex-row items-center justify-between">
            <h3 className="text-secondary-100 text-sm font-semibold">
              {contact?.name}
            </h3>
            <div className="absolute right-1 -top-2 flex flex-col justify-end items-end">
              <div className="flex items-center gap-2">
                {/* <h5 className=" text-xs text-gray-400 font-semibold rounded-xl">
                  {chat?.message?.last_message}
                </h5> */}
                {/* <MenuList
                  items={[
                    {
                      icon: CheckCircleIcon,
                      name: "Résolu",
                    },
                    {
                      icon: PencilIcon,
                      name: "Epinglé",
                    },
                    {
                      icon: BellSlashIcon,
                      name: "Silencieux",
                    },
                    {
                      icon: TrashIcon,
                      name: "Supprimer",
                    },
                    {
                      icon: ArrowsRightLeftIcon,
                      name: "Assigner",
                    },
                  ]}
                  icon={<ChevronDownIcon className="h-4 w-4 text-gray-400" />}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
