import { ApiRoute } from '../../utils/api-route';
import { headers } from "../../utils/utils";
import { Ticket } from '../../models/crm/passenger/ticket-model';
import { Travel } from '../../models/crm/passenger/travel-model';
import { Reservation } from '../../models/crm/passenger/reservation-model';

export async function fetchTickets(): Promise<Ticket[] | []> {
    try {
      const response = await fetch(ApiRoute.passenger + "/tickets" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const tickets: Ticket[] = data.data;
  
      return tickets;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

export async function fetchTrips(): Promise<Travel[] | []> {
    try {
      const response = await fetch(ApiRoute.passenger + "/trips" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const trips: Travel[] = data.data;
  
      return trips;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

export async function fetchReservations(): Promise<Reservation[] | []> {
  try {
    const response = await fetch(ApiRoute.passenger + "/reservations" , {
      method: "GET",
      headers: headers()
    });

    if (response.status != 200) {
      //toast.error(response.status.toString());
      throw new Error(response.status.toString());
    }

    const data = await response.json();
    const reservations: Reservation[] = data.data;

    return reservations;
  } catch (e) {
    throw new Error("Une erreur c'est produite.");
  }
}

 