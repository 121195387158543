import React from "react";

interface StepTreeProps {
  handleNext: () => void;
  setName: (name: string) => void;
  name: string;
  cancel: () => void;
}
export default function NamePromotionalCampain({ handleNext, setName, name, cancel }: StepTreeProps) {
  const [campainName, setCampainName] = React.useState<string>();
  const [error, setError] = React.useState<boolean>(false);
  
  return (
    <div className="border">
      <div className="flex flex-col divide-y">
        <div className="grid grid-cols-2 px-4 py-36 gap-x-4 ">
          <div className="flex items-center justify-center">
            <img
              src="/assets/images/campagnes/campaignes-1.png"
              alt=""
              className="h-52 w-52"
            />
          </div>
          <div className="flex flex-col justify-center items-start gap-y-4 ">
            <h1 className="text-lg text-center font-semibold text-gray-500">
              Nommez votre campagne
            </h1>
            <h5 className="text-xs text-center font-normal text-gray-400 max-w-md">
              Saisissez un nom qui vous aide à identifier cette campagne.
            </h5>
            <input
              type="text"
              className={"border rounded-md h-10 w-full px-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600 "+ (error ? "border-red-300":"")}
              placeholder="Entrez le nom de la campagne"
              defaultValue={name}
              onKeyUp={(e) => {
                setCampainName(e.currentTarget.value)
                if (campainName === "") {
                  setError(true)
                } else {
                  setError(false)
                }
              }}
            />
            <span className={"text-xs text-center font-normal text-red-400 max-w-md " + (error ? "":"hidden")}>
              Saisissez un nom qui vous aide à identifier cette campagne.
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={cancel}>
            Annuler
          </button>
          <button
            onClick={() => {
              if (typeof campainName != "undefined" && campainName !== "") {
                setName(campainName);
                handleNext()
              } else {
                setError(true)
              }
            }}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
}
