import { WhatsappIcon } from "./icons";

export default function NoOpenChat() {
    return (
      <div className="flex items-center justify-between min-h-max">
        <div className="text-center">
            <WhatsappIcon/>
            {/* <p className="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum similique molestias delectus iusto?</p> */}
        </div>
      </div>
    );
  }
  