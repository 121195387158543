import { TableColumn } from "react-data-table-component"
import { Invoice } from "../../models/crm/invoice-model"
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react/jsx-runtime";

const columns: TableColumn<Invoice>[] = [
  {
    name: 'No VTE',
    selector: (row: Invoice) => row.numeroVente,
    cell(row) {
      return (
      <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span className="text-purple-600">
            {row.numeroVente}
          </span>
          <span className="text-xs font-light text-capitalize mt-1">
            Vendu le { row.dateVente }
          </span>
        </div>
      );
  },
  },
  {
    name: 'CLIENT',
    selector: (row: Invoice) => row.client.nomClient,
  },
  {
    name: 'MONTANT',
    selector: (row: Invoice) => "XAF " + row.montantTotalNet,
  },
  {
    name: 'MODE PAIEMENT',
    selector: (row: Invoice) => row.modePaiement.libelle,
    cell(row) {
      return (
        <div className=" px-3 py-4 text-sm text-gray-500">
          <span 
          className={"inline-flex items-center rounded-md bg-"+(row.modePaiement.code == "MP001"?"blue":"orange")+"-200 px-2 py-1 text-xs font-medium text-"+(row.modePaiement.code == "MP001"?"blue":"orange")+"-800 ring-1 ring-inset ring-"+(row.modePaiement.code == "MP001"?"blue":"orange")+"-600/20"}>
          {row.modePaiement.libelle}
          </span>
        </div>
      );
    },
  },
  {
    name: 'STATUS',
    selector: (row: Invoice) => row.numeroVente,
    cell(row) {
      if (row.status != null) {
        return (
          <div className=" px-3 py-4 text-sm text-gray-500">
            <span 
            className={"inline-flex items-center rounded-md bg-"+(row.status.code == "102"?"yellow":"green")+"-200 px-2 py-1 text-xs font-medium text-"+(row.status.code == "102"?"yellow":"green")+"-800 ring-1 ring-inset ring-"+(row.status.code == "102"?"yellow":"green")+"-600/20"}>
            {row.status.libelle}
            </span>
          </div>
        );
      } else {
        return (
          <div className=" px-3 py-4 text-sm text-gray-500">
            <span 
            className={"inline-flex items-center rounded-md bg-"+(row.statusCotation.code == "102"?"yellow":"green")+"-200 px-2 py-1 text-xs font-medium text-"+(row.statusCotation.code == "102"?"yellow":"green")+"-800 ring-1 ring-inset ring-"+(row.statusCotation.code == "102"?"yellow":"green")+"-600/20"}>
            {row.statusCotation.libelle}
            </span>
          </div>
        );
      }
    },
  },
  {
    name: 'AGENCE',
    selector: (row: Invoice) => row.agence.nom,
  },
  {
    name: 'CREE PAR',
    selector: (row: Invoice) => row.numeroVente,
    cell(row) {
      return (
      <div className="flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          <span className="text-green-600">
            {row.createdBy.firstName +" "+ row.createdBy.lastName}
          </span>
          <span className="text-xs font-light text-capitalize mt-1">
            le { row.createdAt }
          </span>
        </div>
      );
  },
  },
  {
    name: 'ACTIONS',
    selector: (row: Invoice) => row.numeroVente,
    cell: (row: Invoice) => {
      return (
        <Menu
          as="div"
          className="relative inline-block text-left"
        >
          <div>
            <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
              <EllipsisVerticalIcon className="h-6 w-6" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? "bg-purple-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        
                      >
                        {active ? (
                          <EyeIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <EyeIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        )}
                        Voir
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active
                          ? "bg-purple-500 text-white"
                          : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <TrashIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      ) : (
                        <TrashIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      )}
                      Supprimer
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      );
    }
  },
  
  
];

export default columns;
