import { replace } from "lodash";
import { Contact, WhatsappContact } from "../models/contact-model";
import { Chat, Message } from "../models/message-model";
import { ApiRoute } from "../utils/api-route";
import { headers } from "../utils/utils";
// import { //toast } from "react-//toastify";
import { ContactGroup } from "../models/contact-group-model";
import { Catalog } from "../models/catalog-model";

export async function fetchContacts(): Promise<Contact[] | []> {
    try {
      const response = await fetch(ApiRoute.database_contacts , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const contacts: Contact[] = data.data;
  
      return contacts;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return [];
    }
  }

export async function fetchContactGroups(): Promise<ContactGroup[] | []> {
    try {
      const response = await fetch(ApiRoute.database_contacts + "/groups" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const groups: ContactGroup[] = data.data;
  
      return groups;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return [];
    }
  }
export async function fetchCatalogs(): Promise<Catalog[] | []> {
    try {
      const response = await fetch(ApiRoute.catalogs + "" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const catalog: Catalog[] = data.data;
  
      return catalog;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return [];
    }
  }

export async function uploadContactsFile(body: FormData): Promise<Contact[] | []> {
    try {
      const response = await fetch(ApiRoute.database_contacts + "/import" , {
        method: "POST",
        // headers: headers({ contentType : "multipart/form-data" }),
        body: body,
        redirect: "follow"
      });
      
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const contacts: Contact[] = data.data;
  
      return contacts;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return [];
    }
  }

export async function storeContacts(body: object): Promise<boolean> {
    try {
      const response = await fetch(ApiRoute.database_contacts + "/create" , {
        method: "POST",
        headers: headers(),
        body: JSON.stringify(body),
        redirect: "follow"
      });
      
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      return true;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return false;
    }
  }

export async function updateContact(body: object, id: number): Promise<boolean> {
    try {
      const response = await fetch(ApiRoute.database_contacts + "/update/" + id , {
        method: "PUT",
        headers: headers(),
        body: JSON.stringify(body),
        redirect: "follow"
      });
      
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      return true;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return false;
    }
  }
export async function deleteContact(id: number): Promise<boolean> {
    try {
      const response = await fetch(ApiRoute.database_contacts + "/delete/" + id , {
        method: "DELETE",
        headers: headers(),
        redirect: "follow"
      });
      
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      return true;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return false;
    }
  }

export async function synchronizeContact(): Promise<boolean> {
    try {
      const response = await fetch(ApiRoute.database_contacts + "/sync" , {
        method: "POST",
        headers: headers(),
        redirect: "follow"
      });
      
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      return true;
    } catch (e) {
      //toast.error("Une erreur c'est produite.");
      throw new Error("Une erreur c'est produite.");
  
      return false;
    }
  }


 