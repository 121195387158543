import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ClockIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Editor from "../../../../components/editor";
import TurndownService from "turndown"
import { toast } from "react-toastify";
import { toastConfig } from "../../../../utils/utils";

const message = [
  { name: "4 message par minute" },
  { name: "3 message par minute" },
  { name: "2 message par minute" },
  { name: "1 message par minute" },
];
const frequencies = [
  {
    name: "Envoi Unique",
    value: "ONE_TIME",
    id: 1,
  },
  {
    name: "Journalier",
    value: "DAILY",
    id: 2,
  },
  {
    name: "Hebdomadaire",
    value: "WEEKLY",
    id: 3,
  },
  {
    name: "Mensuel",
    value: "MONTHLY",
    id: 4,
  },
  {
    name: "Annuel",
    value: "YEARLY",
    id: 5,
  },
  
];

const days = [
  {
    name: "DIMANCHE",
    value: "SUN",
    id: 1,
  },
  {
    name: "LUNDI",
    value: "MON",
    id: 2,
  },
  {
    name: "MARDI",
    value: "TUE",
    id: 3,
  },
  {
    name: "MERCREDI",
    value: "WED",
    id: 4,
  },
  {
    name: "JEUDI",
    value: "THU",
    id: 5,
  },
  {
    name: "VENDREDI",
    value: "FRI",
    id: 6,
  },
  {
    name: "SAMEDI",
    value: "THU",
    id: 7,
  },
  
];


interface StepFiveProps {
  handleNext: () => void;
  setCampainFrequency: (c:string) => void;
  setCampainMessage: (c:string) => void;
  setCampainDispatchDate: (c:string) => void;
  setCampainFrequencyDate: (c:string) => void;
  setCampainDispatchTime: (c:string) => void;
  cancel: () => void;
}
export default function ConfigureCampainFrequency({ 
  handleNext, 
  setCampainFrequency, 
  setCampainDispatchDate, 
  setCampainDispatchTime, 
  setCampainFrequencyDate, 
  setCampainMessage,
  cancel
}: StepFiveProps) {
  const [frequency, setFrequency] = React.useState(frequencies[1]);
  const [weeekDay, setWeekDay] = React.useState(days[1]);
  const [daysError, setDaysError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [dispatchDate, setDispatchDate] = React.useState("");
  const [dispatchTime, setDispatchTime] = React.useState("");
  const [frequencyDate, setFrequencyDate] = React.useState("");
  const turndownService = new TurndownService();

  function formatMinDate() {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();

    return year + '-' + month + '-' + day; 
  }

  function validate() {
    switch (frequency.id) {
      case 1:
        // console.log(dispatchDate);
        // console.log(new Date()., new Date().getTime())
        // if (dispatchDate == "" || new Date(dispatchDate).getTime() < new Date().getTime()) {
        //   toast.warning("Veillez bien remplir Une date future comme date de livraison", toastConfig)
        //   return "dispatch_date";
        // }
        setCampainDispatchDate(dispatchDate);
        break;
      case 3:
        if (weeekDay.id == 0 ) {
          toast.warning("Veillez Une date de la semaine", toastConfig)
          return "weekday";
        }
        setCampainFrequencyDate(weeekDay.value)
        break;
      case 4:
        if (frequencyDate == "") {
          toast.warning("Veillez Remplir un jour dans le mois", toastConfig)
          return "frequency_date";
        }
        setCampainFrequencyDate(frequencyDate)
        break;
      default:
        break;
    }
    if (message == "") {
      toast.warning("Veillez Saisir le message d'envoi", toastConfig)
      return "message";
    }
    setCampainMessage(message)
    if (dispatchTime == "") {
      toast.warning("Veillez choisir une heur d'envoi", toastConfig)
      return "dispatch_time";
    }
    setCampainDispatchTime(dispatchTime)
    setCampainFrequency(frequency.value)
    return true;
  }
  return (
    <div className="border mt-8">
      <div className="flex flex-col divide-y">
        <div className="grid grid-cols-2 px-4 py-8 gap-x-4 ">
          <div className="flex flex-col justify-center items-center py-24 gap-y-">
            <img
              src="/assets/images/campagnes/campagne-2.png"
              alt=""
              className="h-64 w-56"
            />
          </div>
          <div className="py-12 flex flex-col gap-y-8 w-full">
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="text-base text-center font-normal text-gray-500">
                Frequence de livraison des messages <code className="text-red-500">*</code>
              </h1>
              {/* <h5 className="text-sm text-start font-light text-gray-400 mt-1">
                La frequence de livraison recommandée est de 1 message par minute
                ou moins.
              </h5> */}
              <Listbox value={frequency} onChange={setFrequency}>
                <div className="relative mt-1 w-full">
                  <Listbox.Button className="relative w-full cursor-default focus:outline-purple-600 rounded-md bg-white py-2 pl-8 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate text-sm text-gray-600">
                      {frequency.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 left-2 flex items-center pr-2">
                      <ClockIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {frequencies.map((freq, freqIdx) => (
                        <Listbox.Option
                          key={freqIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-purple-100 text-purple-900"
                                : "text-gray-900"
                            }`
                          }
                          value={freq}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                } text-xs text-gray-600`}
                              >
                                {freq.name}
                              </span>
                              <span
                                className={classNames(
                                  selected ? "ext-purple-600" : "",
                                  "absolute inset-y-0 left-0 flex items-center pl-3 t"
                                )}
                              >
                                <ClockIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <span className="text-xs text-start font-light text-gray-400  mt-1">
                Achèvement estimé en une minute pour 1 messages.
              </span>
            </div>
            <div className={"flex flex-col justify-start items-start w-full "}>
              <h1 className="text-base text-center font-normal text-gray-500">
                Heure de livraison de la campagne <code className="text-red-500">*</code>
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400  mt-1">
                Sélectionnez une heur a la quelle les messages seront envoye
              </h5>
              <input
                type="time"
                className="border rounded-md h-10 w-full px-2 mt-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-600  focus:outline-purple-600"
                placeholder="Entrez le nom de la campagne"
                onChange={(e) => setDispatchTime(e.currentTarget.value)}
              />
              {/* <span className="text-xs text-start font-light text-gray-400  mt-1">
                Vous pouvez envoyer une campagne par jour, jusqu'à 10 par mois
                dans votre plan d'abonnement actuel.
              </span> */}
            </div>
            <div className={"flex flex-col justify-start items-start w-full "+ (frequency.id == 1? "": "hidden")}>
              <h1 className="text-base text-center font-normal text-gray-500">
                Date de livraison de la campagne <code className="text-red-500">*</code>
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400  mt-1">
                Sélectionnez une date future pour executer cette campagne
              </h5>
              <input
                type="date"
                className="border rounded-md h-10 w-full px-2 mt-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-600  focus:outline-purple-600"
                placeholder="Entrez le nom de la campagne"
                onChange={(e) => setDispatchDate(e.currentTarget.value)}
                min={formatMinDate()}
              />
              <span className="text-xs text-start font-light text-gray-400  mt-1">
                Vous pouvez envoyer une campagne par jour, jusqu'à 10 par mois
                dans votre plan d'abonnement actuel.
              </span>
            </div>
            <div className={"flex flex-col justify-start items-start w-full " + (frequency.id == 3? "": "hidden")}>
              <h1 className="text-base text-center font-normal text-gray-500">
                Selectionnez Jour de la Semaine
              </h1>
              {/* <h5 className="text-sm text-start font-light text-gray-400 mt-1">
                La frequence de livraison recommandée est de 1 message par minute
                ou moins.
              </h5> */}
              <Listbox value={weeekDay} onChange={setWeekDay}>
                <div className="relative mt-1 w-full">
                  <Listbox.Button className="relative w-full cursor-default focus:outline-purple-600 rounded-md bg-white py-2 pl-8 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate text-sm text-gray-600">
                      {weeekDay.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 left-2 flex items-center pr-2">
                      <CalendarDaysIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {days.map((day, dayIdx) => (
                        <Listbox.Option
                          key={dayIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-purple-100 text-purple-900"
                                : "text-gray-900"
                            }`
                          }
                          value={day}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                } text-xs text-gray-600`}
                              >
                                {day.name}
                              </span>
                              <span
                                className={classNames(
                                  selected ? "ext-purple-600" : "",
                                  "absolute inset-y-0 left-0 flex items-center pl-3 t"
                                )}
                              >
                                <CalendarDaysIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              <span className="text-xs text-start font-light text-gray-400  mt-1">
                Achèvement estimé en une minute pour 1 messages.
              </span>
            </div>
            <div className={"flex flex-col justify-start items-start w-full " + (frequency.id == 4? "": "hidden")}>
              <h1 className="text-base text-center font-normal text-gray-500">
                Jour dans le mois de livraison de la campagne
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400  mt-1">
                Sélectionnez le jour dans le mois
              </h5>
              <input
                type="number"
                className={"border rounded-md h-10 w-full px-2 mt-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-600  focus:outline-purple-600 " + (daysError ? "border-red-600" : "")}
                placeholder="Entrez le jour ()"
                // value={"1"}
                onKeyUp={(e) => {
                  if (parseInt(e.currentTarget.value) > 28 || parseInt(e.currentTarget.value) < 1) {
                    setDaysError(true);
                  } else {
                    setFrequencyDate(e.currentTarget.value)
                    setDaysError(false);
                  }
                }}
              />
              {daysError? (
                <span className="text-xs text-start font-light text-red-600  mt-1">
                  Vous devez choisir une date entre le 1er et le 28 pour accommoder les jours de Fevrier.
                </span>
              ):(<></>)}
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <h1 className="text-base text-center font-normal text-gray-500">
                Message
              </h1>
              <h5 className="text-sm text-start font-light text-gray-400 mt-1 mb-2">
                Decrivez le message a attacher aux images ou le message a envoyer
              </h5>
              {/* <textarea id="message" rows={5} className="block p-2.5 w-full text-sm  rounded-lg border mt-2"></textarea> */}
              <Editor loading={false} setText={(t: string) => setMessage(turndownService.turndown(t))}  />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-2">
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={cancel}>
            Annuler
          </button>
          <button
            onClick={() => {
              if (validate() === true) {
                handleNext()
              }
            }}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
}
