import { FINANCES_DASHBOARD } from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import Chart from "../dashboard/components/chart";

export default function FinancePage() {
  const { selectChildren } = useLayoutContext();

  const ChildrenCFinance = () => {
    switch (selectChildren) {
      case FINANCES_DASHBOARD:
        return <Chart />;

      default:
        return <Chart />;
    }
  };
  return (
    <div className="xl:px-4 pt-14 h-full bg-white overflow-auto">
      {ChildrenCFinance()}
    </div>
  );
}
