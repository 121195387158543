import { ChatMessage, InputFormMessage, MenuList } from "../../../components";

import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  TicketIcon,
  FilmIcon,
  CalculatorIcon,
} from "@heroicons/react/24/outline";
import { useLayoutContext } from "../../../core/context/layout-context";
import { Messages } from "../../../layouts";
import AsideInfo from "../../../layouts/aside-info";
import React from "react";
import { WhatsappContact } from "../../../models/contact-model";
import { getContactAvatar, getContactDetails, getTemplates, sendMedia } from "../../../services/chat-service";
import NoOpenChat from "../../../components/no-open-chat";
import { Chat, MessageContent, Message as MessageModel } from "../../../models/message-model";
import { toast, ToastContainer } from "react-toastify";
import { toastConfig } from "../../../utils/utils";
import { NotificationTemplate } from "../../../models/notification-template-model";
import { ApiRoute } from "../../../utils/api-route";
import ChatMessageAgents from "../../../components/chat-message-agents";
import { fetchUser } from "../../../services/auth-service";
import { AgentChatMessage, AgentConversation } from "../../../models/agent-chat-model";
import { Auth } from "../../../models/auth-model";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { isArray } from "lodash";
import { time } from "console";
import TurndownService from "turndown";

interface MessageProps {
  showContactList?: boolean;
}
interface whatsappMessageReceivedProps {
  from: string;
  body: string;
  pushname: string;
  type: string;
  time: number;
}

const chat_user_types = [
  {
    id: 1,
    value: "WHATSAPP"
  },
  {
    id: 2,
    value: "DB_CONTACT"
  },
  {
    id: 3,
    value: "USER" //or agent
  },
  
];
export default function Message({ showContactList }: MessageProps) {
  const { asideOpen } = useLayoutContext();
  const [openChat, setOpenChat] = React.useState<number>(0);
  const [phone, setPhone] = React.useState<string>("");
  const [contact, setContact] = React.useState<WhatsappContact>();
  const [avatar, setAvatar] = React.useState<string>("");
  const [chats, setChats] = React.useState<MessageModel>();
  const [whatsappChats, setWhatsappChats] = React.useState<Chat[]>([]);
  const [whatsappChatMessage, setWhatsappChatMessage] = React.useState<MessageModel>();
  const [messageToBeSentType, setMessageToBeSentType] = React.useState<string>("");
  const [textMessage, setTextMessage] = React.useState<string>("");
  const [fileMessage, setFileMessage] = React.useState<File | null>(null);
  const [fileCaption, setFileCaption] = React.useState("");
  const [avatarChecked, setAvatarChecked] = React.useState<Boolean>(false);
  const [templates, setTemplates] = React.useState<Array<NotificationTemplate>>([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState<NotificationTemplate>();
  const [templateVariableValues, setTemplateVariableValues] = React.useState({});
  const [templatesFetched, setTemplatesFetched] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [channelType, setChannelType] = React.useState(1);
  const [connectedPhone, setConnectedPhone] = React.useState("");
  const [newMessage, setNewMessage] = React.useState<whatsappMessageReceivedProps | null>(null);
  const [newMessageReceived, setNewMessageReceived] = React.useState<boolean>();
  const [whatsappContacts, setWhatsappContacts] = React.useState<WhatsappContact[]>([]);
  const [conversations, setConversations] = React.useState<{ [key: string]: AgentConversation }>({});
  const auth :Auth|null = useAuthUser();
  const websocket = React.useRef<WebSocket | null>(null);
  const turndownService = new TurndownService();

  React.useEffect(() => {
    auth!.options.forEach(opt => {
      if (opt?.name == "conversation_phone_whatsapp_id") {
        setConnectedPhone(opt?.value);
      }
    })
    
    websocket.current = new WebSocket("wss://"+ApiRoute.websocketUrl);
    websocket.current.onopen = () => {
      if (websocket.current && websocket.current.readyState == WebSocket.OPEN) {
        websocket.current?.send(JSON.stringify({ event: 'register', clientId: auth?.user.email, data: {} }));
        websocket.current?.send(JSON.stringify({ 
          event: 'chats', 
          clientId: auth?.user.email,
          data: {
            from: auth?.user.email,
            to: auth?.user.email
          }
          }));
          websocket.current!.send(JSON.stringify({
          event: 'contacts',
          clientId: auth!.user.email,
          data: {
              to: phone,
              from: auth!.user.email,
            },
        }));
      }
    };

    websocket.current.onmessage = (event) => {
      if (typeof event.data == "string") {
        const messageData = JSON.parse(event.data);
        const { event: messageEvent, to, from, data } = messageData;
        switch (messageEvent) {
          case "chats":
            if (isArray(data)) {
              setWhatsappChats(data);
            }   
            break;
          case "chat-messages":
              setWhatsappChatMessage(data);
            break;
          case "text-web-to-web":
            updateConversation(phone,[data])
            break;
          case "group-group-id":
             
            break;
          case "contacts":
             setWhatsappContacts(data)
            break;
          case "contacts-contact":
            setContact(data);
            if (contact?.id === "" && contact?.number === "") {
              toast.error("Ce Numero n'a pas de compte Whatasapp", toastConfig)
            }
            break;
          case "message": 
            setNewMessage(data)
            setTimeout(() => {
              setNewMessageReceived(true);
            },1000)
            if (data?.from != connectedPhone) {
              toast.success(turndownService.turndown(`${data!.pushname}: ${data!.body}`), toastConfig)
            }
            break;
          
          case "contacts-image":
            if (typeof data?.success != "undefined") {
              setAvatar(data.success);
            } else {
              setAvatar("/assets/images/img-1.jpg")
            }
            break;
          case "groups":
             
            break;
          
          default:
            break;
        }
      }
    };
    
    if (!templatesFetched) {
      getTemplates().then((data: NotificationTemplate[]) => {
        setTemplates(data);
        setTemplatesFetched(true);
      }).catch((e) => {
        toast.error("Une Erreur Technique s'est Lors de la recuperation des templates", toastConfig)
      })
    }
    if ((typeof phone == "string" && phone != "") && channelType != 3) {
      whatsappChats!.forEach(wc => {
        if (wc.phone == phone) {
          setContact({ 
            id: wc.phone,
            name: wc.name == ""? wc.phone: wc.name,
            number: wc.phone,
            pushname: wc.phone,
            labels: [],
            isMe: false,
            isGroup: false,
            isBusiness: false,
            isEnterprise: false,
            isMyContact: false,
            isBlocked: false,
            isMuted: false,
          });
        }
      });
      setTimeout(()=> {
        if (websocket.current && websocket.current.readyState == 1 && typeof newMessageReceived == "undefined") {
          
          websocket.current?.send(JSON.stringify({ 
            event: 'chat-messages', 
            clientId: auth?.user.email,
            data: {
              from: auth?.user.email,
              chatId: phone,
              limit: 100
            }
          }));

          websocket.current?.send(JSON.stringify({ 
            event: 'contacts-contact', 
            clientId: auth?.user.email,
            data: {
              chatId: phone,
              from:auth?.user.email,
            }
          }));
          websocket.current?.send(JSON.stringify({ 
            event: 'contacts-image', 
            clientId: auth?.user.email,
            data: {
              chatId: phone,
              from:auth?.user.email,
            }
          }));
          
          
          
        }
      }, 3000)
    }
    if ((typeof phone == "string" || phone != "") && channelType == 3) {
      fetchUser(phone).then((contact) => {
        setContact({ 
          id: contact.email,
          name: contact.name,
          number: contact.email,
          pushname: "",
          labels: [],
          isMe: false,
          isGroup: false,
          isBusiness: false,
          isEnterprise: false,
          isMyContact: false,
          isBlocked: false,
          isMuted: false,
        });
      }).catch((e) => {
        toast.error("Erreur lors de la recuperation des informations", toastConfig)
      });
    }
    websocket.current.onerror = (error) => {
        console.error('WebSocket error:', error);
    };
    
    if (newMessage != null && newMessageReceived) {
      let temp_messages = whatsappChatMessage?.messages;
      if (newMessage?.from != connectedPhone) {
        if (newMessage?.from == phone) {
          temp_messages?.push({
            id: 0,
            datetime: newMessage?.time,
            message: newMessage?.body,
            owner: newMessage?.from,
            status: "",
            type: newMessage?.type
          })
          setWhatsappChatMessage({
            ...whatsappChatMessage!,
            messages: temp_messages!
          })
        }
      }
      if (phone != newMessage?.from) {
        setTimeout(() => {
          if (websocket.current && websocket.current.readyState == WebSocket.OPEN) {
            websocket.current?.send(JSON.stringify({ 
              event: 'chats', 
              clientId: auth?.user.email,
              data: {
                from: auth?.user.email,
                to: auth?.user.email
              }
              }));
          }
        }, 5000)
        }
        setNewMessageReceived(false);
      }
    

  },[phone, newMessageReceived]);

  
  function updateConversation (phone: string, newMessages: AgentChatMessage[]) 
  {
    Array.isArray(newMessages) &&  setConversations(prevConversations => ({
        ...prevConversations,
        [phone]: {
            ...prevConversations[phone],
            messages: [...(prevConversations[phone]?.messages || []), ...newMessages]
        }
    }));
  };
  function displayChatType() {
    switch (channelType) {
      case 1:
        return (
          <>
            <ChatMessage phone={phone} avatar={avatar} setChat={(ch) => setWhatsappChatMessage(ch)} chat={whatsappChatMessage!}  />
            <InputFormMessage 
              phone={phone} 
              setTemplateContent={(values) => setTemplateVariableValues(values)}
              setTemplate={(t) => {
                setSelectedTemplate(t)
              }}
              templates={templates}
              template={selectedTemplate}
              setShowPreview={(s) => setShowPreview(s)}
              chatType="WHATSAPP"
              fileMessage={fileMessage}
              setFileMessage={(file) => {
                setFileMessage(file)
              }}
              setTextMessage={(text) => {
                setTextMessage(text)
              }}
              textMessage={textMessage}
              setMessageToBeSentType={(mtbs) => setMessageToBeSentType(mtbs)}
              submitMessage={() => {
                sendMessage();
              }}
              setCaption={(c) => setFileCaption(c)}
            />
          </>
          );
      case 2:
        return (
          <>
            <ChatMessage phone={phone} avatar={avatar} setChat={(ch) => setWhatsappChatMessage(ch)} chat={whatsappChatMessage!} />
            <InputFormMessage 
              phone={phone} 
              setTemplateContent={(values) => setTemplateVariableValues(values)}
              setTemplate={(t) => {
                setSelectedTemplate(t)
              }}
              templates={templates}
              template={selectedTemplate}
              setShowPreview={(s) => setShowPreview(s)}
              chatType="DB_CONTACT"
              fileMessage={fileMessage}
              setFileMessage={(file) => setFileMessage(file)}
              setTextMessage={(text) => setTextMessage(text)}
              textMessage={textMessage}
              setMessageToBeSentType={(mtbs) => setMessageToBeSentType(mtbs)}
              submitMessage={() => {
                sendMessage();
              }}
              setCaption={(c) => setFileCaption(c)}
            />
          </>
          );
        break;
      case 3:
        return (
          <>
            <ChatMessageAgents 
            phone={phone} 
            avatar={avatar} 
            updateConversation={(d) => updateConversation(phone,d)}
            conversations={conversations[phone]} 
            refresh={refresh}
            />
            <InputFormMessage 
              phone={phone} 
              setTemplateContent={(values) => setTemplateVariableValues(values)}
              setTemplate={(t) => {
                setSelectedTemplate(t)
              }}
              templates={templates}
              template={selectedTemplate}
              setShowPreview={(s) => setShowPreview(s)}
              chatType="USER"
              fileMessage={fileMessage}
              setFileMessage={(file) => setFileMessage(file)}
              setTextMessage={(text) => setTextMessage(text)}
              textMessage={textMessage}
              setMessageToBeSentType={() => setMessageToBeSentType("text-web-to-web")}
              submitMessage={() => {
                updateConversation(phone, [{
                  from: auth!.user.email,
                  name: auth!.user.name,
                  text: textMessage,
                  to: phone,
                  time: new Date().getTime()
                }])
                sendMessage();
              }}
              setCaption={(c) => setFileCaption(c)}
            />
          </>
        );
      
        break;
      
      default:
        break;
    }
  }

  async function sendMessage() {
    switch (messageToBeSentType) {
      case "text":
      case "text-web-to-web":
        if (websocket.current && websocket.current.readyState == WebSocket.OPEN) {
          const messageObject = {
            event: messageToBeSentType,
            clientId: auth!.user.email,
            data: {
                from:  auth!.user.email,
                to: phone,
                name: phone,
                text: textMessage,
                time: Date.now(),
              },
          };
          websocket.current.send(JSON.stringify(messageObject));
        }
        if (messageToBeSentType == "text") {
          let temp_messages: MessageContent[] = whatsappChatMessage!.messages;
          temp_messages!.push({
            id: 0,
            datetime: new Date().getTime(),
            message: textMessage,
            owner: connectedPhone,
            status:"pending",
            type: "text"
          })
          setWhatsappChatMessage({
            ...whatsappChatMessage!,
            messages: temp_messages
          })
        }
        break;
      
      case "document":
        let docFormData = new FormData();
        docFormData.append("document", fileMessage!);
        docFormData.append("to", phone!);
        docFormData.append("caption", fileCaption);
        docFormData.append("name", fileMessage!.name);
        docFormData.append("filename", fileMessage!.name);
        await sendMedia(docFormData).then((res) => {
          let temp_messages: MessageContent[] = whatsappChatMessage!.messages;
          temp_messages!.push({
            id: 0,
            datetime: new Date().getTime(),
            message: fileCaption == ""? fileMessage!.name: fileCaption, //review
            owner: connectedPhone,
            status:"pending",
            type: "document"
          })
          setWhatsappChatMessage({
            ...whatsappChatMessage!,
            messages: temp_messages
          })
        });
        break;
      case "image":
        let imageFormData = new FormData();
        imageFormData.append("document", fileMessage!);
        imageFormData.append("to", phone!);
        imageFormData.append("caption", fileCaption);
        imageFormData.append("name", fileMessage!.name);
        await sendMedia(imageFormData).then((res) => {
          let temp_messages: MessageContent[] = whatsappChatMessage!.messages;
          temp_messages!.push({
            id: 0,
            datetime: new Date().getTime(),
            message: fileCaption == ""? fileMessage!.name: fileCaption, //review
            owner: connectedPhone,
            status:"pending",
            type: "image"
          })
          setWhatsappChatMessage({
            ...whatsappChatMessage!,
            messages: temp_messages
          })
        });
        break;
      
      default:
        break;
    }
  }
  return (
    <main className="xl:px-80">
      <div className="h-screen">
        <div className={"flex flex-col sticky top-10 w-full bg-white "}>
        {phone != "" && typeof phone == "string"?(
            <>
              <div className="px-4 sm:px-6 lg:px-4 py-10 lg:py-[14px]">
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center gap-x-4 min-w-full">
                    {avatar == "" || typeof avatar == "undefined" ? (
                        <img
                          className="rounded-md h-12 w-12"
                          src="/assets/images/img-1.jpg"
                          alt=""
                        />
                        
                      ):(
                        <img
                          className="rounded-md h-12 w-12"
                          src={avatar}
                          alt=""
                        />
                    )}
                    <div className="flex flex-col md:flex-row md:items-center gap-2 justify-between w-full">
                      <div className="flex flex-col w-full">
                        <h3 className="text-secondary-100 text-lg font-semibold">
                          {contact?.name} {contact?.isMe?"(VOUS)":""}
                          {contact?.name == "" && channelType != 3? contact?.number: ""}
                        </h3>
                        <span className="flex items-center gap-x-1 text-xs text-gray-500 font-normal">
                          <hr className="p-1 bg-green-400 rounded-full" /> Online
                        </span>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <div className="flex flex-row items-center gap-2 w-40">
                          <button
                            type="button"
                            className="bg-yellow-100 hover:bg-yellow-200 text-yellow-500 flex text-xs items-center px-2 py-1 rounded-md gap-x-2"
                          >
                            Non Résolu
                          </button>
                          <button
                            type="button"
                            className="bg-green-100 hover:bg-green-200 text-green-500 flex text-xs items-center px-2 py-1 rounded-md gap-x-2"
                          >
                            Assigner
                          </button>
                        </div>
                        <MenuList
                          items={[
                            {
                              icon: TicketIcon,
                              name: "Ajouter Ticket",
                            },
                            {
                              icon: FilmIcon,
                              name: "Catalogue",
                            },
                            {
                              icon: CalculatorIcon,
                              name: "Devis",
                              separator: true
                            },
                            {
                              icon: CheckCircleIcon,
                              name: "Résolu",
                            },
                            {
                              icon: PencilIcon,
                              name: "Epinglé",
                            },
                            {
                              icon: BellSlashIcon,
                              name: "Silencieux",
                            },
                            {
                              icon: TrashIcon,
                              name: "Supprimer",
                            },
                            {
                              icon: ArrowsRightLeftIcon,
                              name: "Assigner",
                            },
                          ]}
                          icon={
                            <EllipsisVerticalIcon className="h-6 w-6 text-gray-700" />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ):(
            <></>
          )}
          
          <hr />
        </div>
        
        <div className={"flex-grow h-full w-full flex flex-col mt-16 " + (phone != "" && typeof phone == "string"?"":"justify-center items-center")}>
          {phone != "" && typeof phone == "string"?(
            <>
            {displayChatType()}
            </>
          ):(
            <NoOpenChat />
          )}
        </div>
      </div>
      <AsideInfo contact={contact} avatar={avatar} template={selectedTemplate} showPreview={showPreview} templateContent={templateVariableValues} />
      <Messages
        chatDisplay={openChat}
        openChat={(i) => setOpenChat(i)}
        openAside={asideOpen}
        setPhone={(p) => {
          setNewMessageReceived(undefined);
          setPhone(p)
        }}
        setChannelType={(ct) => setChannelType(ct)}
        showContactList={showContactList}
        chats={whatsappChats!}
        whatsappContacts={whatsappContacts}
      />
      <ToastContainer
       position="top-right"
       autoClose={5000}
       
       hideProgressBar={false} // Other desired configurations
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
     />
    </main>
  );
}
