import { ApiRoute } from './../../utils/api-route';
import { headers } from "../../utils/utils";
import { Invoice } from '../../models/crm/invoice-model';
import { Stock } from '../../models/crm/stock-model';

export async function fetchInvoices(): Promise<Invoice[] | []> {
    try {
      const response = await fetch(ApiRoute.yangzi + "/factures" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const invoices: Invoice[] = data.data;
  
      return invoices;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

export async function fetchQuotes(): Promise<Invoice[] | []> {
    try {
      const response = await fetch(ApiRoute.yangzi + "/cotations" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const quotes: Invoice[] = data.data;
  
      return quotes;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

 export async function fetchClientCommand(): Promise<Invoice[] | []> {
    try {
      const response = await fetch(ApiRoute.yangzi + "/commandes-clients" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const commads: Invoice[] = data.data;
  
      return commads;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

 export async function fetchStocks(): Promise<Stock[] | []> {
    try {
      const response = await fetch(ApiRoute.yangzi + "/stocks" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const stocks: Stock[] = data.data;
  
      return stocks;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

 