import React from "react";

export default function AddIcon({ ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 0.333344C7.81668 0.333344 0.333344 7.81668 0.333344 17C0.333344 26.1833 7.81668 33.6667 17 33.6667C26.1833 33.6667 33.6667 26.1833 33.6667 17C33.6667 7.81668 26.1833 0.333344 17 0.333344ZM23.6667 18.25H18.25V23.6667C18.25 24.35 17.6833 24.9167 17 24.9167C16.3167 24.9167 15.75 24.35 15.75 23.6667V18.25H10.3333C9.65001 18.25 9.08334 17.6833 9.08334 17C9.08334 16.3167 9.65001 15.75 10.3333 15.75H15.75V10.3333C15.75 9.65001 16.3167 9.08334 17 9.08334C17.6833 9.08334 18.25 9.65001 18.25 10.3333V15.75H23.6667C24.35 15.75 24.9167 16.3167 24.9167 17C24.9167 17.6833 24.35 18.25 23.6667 18.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
