import classNames from "classnames";
import { UserChat } from "../core/models";

import UserAvatar from "./user-avatar";
import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  CalculatorIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  FilmIcon,
  PencilIcon,
  TicketIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import MenuList from "./menu-list";
import { Contact, WhatsappContact } from "../models/contact-model";
import { Chat } from "../models/message-model";
import React from "react";
import { getContactAvatar, getContactDetails } from "../services/chat-service";
import { getDateFromTimestamp } from "../utils/utils";

interface UserMessageProps {
  chat: Chat;
  className?: string;
  setPhone: () => void;
  openChat: () => void;
}

export default function UserMessage({
  chat,
  className,
  setPhone,
  openChat,
}: UserMessageProps) {

  const [chatDetailsCollected, setChatDetailsCollected] = React.useState<Boolean>(false);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [contact, setContact] = React.useState<WhatsappContact>();
  const [avatar, setAvatar] = React.useState<string>("/assets/images/img-1.jpg");

  React.useEffect(() => {
    if (!chatDetailsCollected) {
      getChatsAndAvatars().then(() => {
        setLoading(true);
        setChatDetailsCollected(true);
      });
    }
  })

  async function getChatsAndAvatars() {
    await getContactAvatar(chat.phone).then((avatar) => {
      if (typeof avatar != "undefined") {
        setAvatar(avatar);
      }
    });
  }

  function openUserChat() {
    setPhone();
    openChat();
  }

  return (
    <div
      onClick={openUserChat}
      className={classNames(
        className,

        "py-2 pl-3 flex justify-between items-center w-full"
      )}
    >
      <div className="flex items-center gap-x-4 min-w-full">
        <img className="rounded-md h-12 w-12" src={avatar} alt="" />
        <div className="flex flex-col gap-y-1 w-full">
          <div className="relative flex flex-row items-center justify-between">
            <h3 className="text-secondary-100 text-sm font-semibold">
              {chat?.name}
            </h3>
            <div className="absolute right-1 -top-2 flex flex-col justify-end items-end">
              <div className="flex items-center gap-2">
                <h5 className=" text-xs text-gray-400 font-semibold rounded-xl">
                  {chat?.message?.last_message}
                </h5>
                <MenuList
                  items={[
                    {
                      icon: TicketIcon,
                      name: "Ajouter Ticket",
                    },
                    {
                      icon: FilmIcon,
                      name: "Catalogue",
                    },
                    {
                      icon: CalculatorIcon,
                      name: "Devis",
                      separator: true
                    },
                    
                    {
                      icon: CheckCircleIcon,
                      name: "Résolu",
                    },
                    {
                      icon: PencilIcon,
                      name: "Epinglé",
                    },
                    {
                      icon: BellSlashIcon,
                      name: "Silencieux",
                    },
                    {
                      icon: TrashIcon,
                      name: "Supprimer",
                    },
                    {
                      icon: ArrowsRightLeftIcon,
                      name: "Assigner",
                    },
                    
                  ]}
                  icon={<ChevronDownIcon className="h-4 w-4 text-gray-400" />}
                />
              </div>
              {chat?.message?.unread_messages > 0 ? (
              <UserAvatar
                type="green"
                name={""+chat?.message?.unread_messages}
                isOnline={false}
                size="md"
              />
              ) : (
                <></>
              )}
            </div>
          </div>
          <p className="text-[10px] text-gray-500 font-normal">
            {getDateFromTimestamp( chat?.message?.last_message_sent_time)}
          </p>
        </div>
      </div>
    </div>
  );
}
