import React from "react";
import CreateCampagne from "./new-campagne/create-campage";
import OnboardingCampagne from "./new-campagne/onboarding-campagne";
import OnboardingPromotionalCampagne from "./new-promotional-campagne/onboarding-campagne";

export default function NewCampagne() {
  const [step, setStep] = React.useState<number>(0);
  const [campainType, setCampainType] = React.useState<number>(0);
  
  return (
    <main className="max-w-7xl mx-auto">
      {step === 0 ? (
            <CreateCampagne handleCreate={() => setStep(1)} setType={setCampainType}/>
          ) : (<></> )}
      {step != 0 && campainType == 1? <OnboardingPromotionalCampagne /> : (<></> )}
      {step != 0 && campainType == 0? <OnboardingCampagne /> : (<></> )}
    </main>
  );
}
