import { marked } from "marked";
import classNames from "classnames";
import React from "react";

interface MobilePreviewProps {
    text: string;
    color?: string;
}
export default function MobilePreview ({ text, color }: MobilePreviewProps) {
  const [col, setCol] = React.useState("purple")
  React.useEffect(() => {
    if (typeof color != "undefined") {
      setCol(color)
    }
  }, [color])
    return (
        <div className="ml-4 flex flex-col gap-8">
          <div className={
            classNames(
              "border-zinc-700",
              "relative mx-auto bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]")}>
            <div className={
              classNames(
                "bg-zinc-700",
                "h-[32px] w-[3px] absolute -left-[17px] top-[72px] rounded-l-lg")}></div>
            <div className={
              classNames(
                "bg-zinc-700",
                "h-[46px] w-[3px] absolute -left-[17px] top-[124px] rounded-l-lg")}></div>
            <div className={
              classNames(
                "bg-zinc-700",
                "h-[46px] w-[3px] absolute -left-[17px] top-[178px] rounded-l-lg")}></div>
            <div className={
              classNames(
                "bg-zinc-700",
                "h-[64px] w-[3px] absolute -right-[17px] top-[142px] rounded-r-lg")}></div>
            <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-gray-100 p-6">
              <h1 className="font-semibold text-sm mb-8">Aperçu du message:</h1>
              <div className="h-[450px] w-full bg-white my-8 rounded-lg p-4">
                <div
                  className="flex flex-wrap w-full break-all"
                  dangerouslySetInnerHTML={{ __html: marked(text!).toString() }}
                />
              </div>
            </div>
          </div>
        </div>
    );
}