import React from "react";
import { Message, MessageContent } from "../models/message-model";
import { getmessages } from "../services/chat-service";
import { getDateFromTimestamp, toastConfig } from "../utils/utils";
import { Discuss } from "react-loader-spinner";
import { toast } from "react-toastify";

interface ChatMessageProps {
  phone: string;
  avatar: string;
  setChat: (c:Message) => void;
  chat: Message;
}

export default function ChatMessage({ phone, avatar, chat, setChat }: ChatMessageProps) {

  React.useEffect(() => {
  },[chat]);

  return (
    <div className="w-full flex-grow my-2 p-2 overflow-y-auto">
      { chat?.messages?.map((message, key) => {
        return (
          <>
          {message.owner == phone?

              <div className={"flex items-end w-3/4 " + (key == (chat.messages.length - 1)? "focus":"")} >
                {avatar != "" && typeof avatar != "undefined"?(
                  <>
                    <img
                      src={avatar}
                      className="w-8 h-8 m-3 rounded-full"
                      alt="avatar"
                    />
                  </>
                  ):(
                    <>
                      <img
                        src="/assets/images/img-1.jpg"
                        className="w-8 h-8 m-3 rounded-full"
                        alt="avatar"
                      />
                    </>
                  )
                }
                <div className="p-3 bg-gray-100 mx-3 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
                  <div className="text-black text-xs ">
                    {message.message}
                  </div>
                  <div className="text-xs text-gray-400 mt-1">{getDateFromTimestamp( message.datetime)}</div>
                </div>
              </div>
            :
            <div className={"flex justify-end "+ (key == (chat.messages.length - 1)? "focus":"")}>
            <div className="flex items-end w-3/4 bg-green-600 m-1 rounded-xl rounded-br-none sm:w-3/4 max-w-xl md:w-auto">
              <div className="p-2">
                <div className="text-white text-xs">
                  {message.message}
                  {/* <Markdown remarkPlugins={[remarkGfm]}>{message.message}</Markdown> */}
                </div>
                <div className="text-xs text-white text-right mt-1">{getDateFromTimestamp( message.datetime)}</div>
              </div>
            </div>
          </div>
          }
        </>
        );
      })
      }
    </div>
  );
}
