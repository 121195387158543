import classNames from "classnames";
import {
  BanknotesIcon,
  BellAlertIcon,
  BookmarkIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { MenuList, Spinner, UploadFile } from "../../../components";
import { isEmpty } from "lodash";
import { Invoice } from "../../../models/crm/invoice-model";
import DataTable from "react-data-table-component";
import columns from "../../../table-columns/crm/invoice-columns";
import React from "react";
import { fetchClientCommand } from "../../../services/crm/yangzi-service";
import { toast } from "react-toastify";
import { toastConfig } from "../../../utils/utils";

const tabs = [
  { name: "Assurance Voyage", href: "#", current: 0 },
  { name: "Assurance Automobile", href: "#", current: 1 },
  { name: "Assurance Automobile List Act", href: "#", current: 2 },
  { name: "Assurance Santé", href: "#", current: 3 },
];
export default function ClientCommands() {
  const [tab, setTab] = React.useState<number>(0);
  const [uploadFileDialog, setUploadFileDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [invoicesFetched, setInvoicesFetched] = React.useState<boolean>(false);
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);

  React.useEffect(() => {
    if (!invoicesFetched) {
      setLoading(true)
      fetchClientCommand().then((inv) => {
        setInvoices(inv);
        setInvoicesFetched(true);
      }).catch(() => {
        toast.error("Une erreur technique s'est produite, reessayer plus tard", toastConfig)
      }).finally(() => {
        setLoading(false);
      })
      
    }
  })



  return (
    <>
      <div className="max-w-7xl mx-auto ">
        <div className="flex flex-col divide-y ">
          <div className=" px-4 py-4 gap-x-4  ">
            <div className="">
            <div className="flex items-center gap-2 ">
                <BookmarkIcon className="h-6 w-6" />
                <h1>Commandes Clients</h1>
              </div>
              {/* <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
                    defaultValue={tabs.find((tab) => tab.current)!.name}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                      {tabs.map((item) => (
                        <span
                          key={item.name}
                          onClick={() => setTab(item.current)}
                          className={classNames(
                            item.current === tab
                              ? "border-blue-500 text-blue-600"
                              : "border-transparent cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </span>
                      ))}
                    </nav>
                  </div>
                </div>
              </div> */}
              <div className="pt-8 ">
                <div className="flex items-center gap-x-2 pb-4">
                  {/* <a className="text-white bg-blue-600 hover:bg-blue-500 px-4 py-2 rounded-lg text-sm cursor-pointer">
                    Nouvelle Cotation
                  </a> */}
                </div>
                <div className="flex flex-col divide-y border ">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-blue-600"
                      placeholder="Rechercher une Commande"
                    />
                    <div className="flex items-center gap-x-2">
                      <a className="text-white bg-green-600 hover:bg-green-500 px-4 py-2 rounded-lg text-sm cursor-pointer">
                        Télécharger en Exel
                      </a>
                      <a className="text-white bg-red-600 hover:bg-red-500 px-4 py-2 rounded-lg text-sm cursor-pointer">
                        Télécharger en PDF
                      </a>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="relative ">
                      {tab === 0 ? (
                        <DataTable
                          columns={columns}
                          data={invoices}
                          pagination
                          progressPending={loading}
                          progressComponent={
                            <div className="my-2">
                              <Spinner size="sm" />
                            </div>
                          }
                        />
                      ) : (
                        // <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        //   <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        //     <tr>
                        //       <th scope="col" className="px-6 py-3">
                        //         Date Recep. dem.
                        //       </th>
                        //       <th scope="col" className="px-6 py-3">
                        //         Véhicule
                        //       </th>
                        //       <th scope="col" className="px-6 py-3">
                        //         Type Véh.
                        //       </th>
                        //       <th scope="col" className="px-6 py-3">
                        //         Propriétaire
                        //       </th>
                        //       <th scope="col" className="px-6 py-3">
                        //         Desc Véh
                        //       </th>
                        //       <th scope="col" className="px-6 py-3">
                        //         Type Contrat
                        //       </th>

                        //       <th scope="col" className="px-6 py-3">
                        //         Status
                        //       </th>
                        //       <th scope="col" className="px-6 py-3">
                        //         <span className="">Actions</span>
                        //       </th>
                        //     </tr>
                        //   </thead>
                        //   <tbody>
                        //     {quoteAuto.map((item, i) => {
                        //       return (
                        //         <tr
                        //           key={item.date}
                        //           className={classNames(
                        //             {
                        //               "border-b": i + 1 !== quoteAuto.length,
                        //             },
                        //             "bg-white  text-xs hover:bg-gray-50 "
                        //           )}
                        //         >
                        //           <th
                        //             scope="row"
                        //             className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                        //           >
                        //             {item.date}
                        //           </th>
                        //           <td className="px-6 py-4">{item.client}</td>
                        //           <td className="px-6 py-4">{item.depart}</td>
                        //           <td className="px-6 py-4">
                        //             {item.destination}
                        //           </td>
                        //           <td className="px-6 py-4">
                        //             {item.dateDepart}
                        //           </td>
                        //           <td className="px-6 py-4">{item.duree}</td>
                        //           <td className="px-2 py-4">
                        //             {" "}
                        //             <span
                        //               className={classNames(
                        //                 {
                        //                   "bg-green-100 text-green-600":
                        //                     item.status === "Traité",
                        //                 },
                        //                 {
                        //                   "bg-red-100 text-red-600":
                        //                     item.status === "Non Traité",
                        //                 },
                        //                 " px-4 py-1 font-semibold rounded-md text-xs"
                        //               )}
                        //             >
                        //               {item.status}
                        //             </span>
                        //           </td>
                        //           <td className="px-6 py-4 text-right">
                        //             <MenuList
                        //               items={[
                        //                 {
                        //                   icon: RocketLaunchIcon,
                        //                   name: "Relancer",
                        //                 },
                        //                 {
                        //                   icon: MegaphoneIcon,
                        //                   name: "Traité",
                        //                   action: () => {
                        //                     setError("");
                        //                     setSuccess("");
                        //                     setUploadFileDialog(true);
                        //                   },
                        //                 },
                        //                 {
                        //                   icon: EyeIcon,
                        //                   name: "Détails",
                        //                 },
                        //               ]}
                        //               icon={
                        //                 <EllipsisVerticalIcon className="h-6 w-6 text-gray-700" />
                        //               }
                        //             />
                        //           </td>
                        //         </tr>
                        //       );
                        //     })}
                        //   </tbody>
                        // </table>
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
