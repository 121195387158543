import { ApiRoute } from '../../utils/api-route';
import { headers } from "../../utils/utils";
import { Expedition } from '../../models/crm/clementino/expedition-model';
import { Stock } from '../../models/crm/stock-model';

export async function fetchExpeditions(): Promise<Expedition[] | []> {
    try {
      const response = await fetch(ApiRoute.clementino + "/expeditions" , {
        method: "GET",
        headers: headers()
      });
  
      if (response.status != 200) {
        //toast.error(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const expeditions: Expedition[] = data.data?.entities;
  
      return expeditions;
    } catch (e) {
      throw new Error("Une erreur c'est produite.");
    }
  }

 