export default function TextItalicIcon({
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.88 3.75H9.62C9.21 3.75 8.88 3.41 8.88 3C8.88 2.59 9.22 2.25 9.63 2.25H18.88C19.29 2.25 19.63 2.59 19.63 3C19.63 3.41 19.29 3.75 18.88 3.75Z"
        fill="currentColor"
      />
      <path
        d="M14.38 21.75H5.12C4.71 21.75 4.37 21.41 4.37 21C4.37 20.59 4.71 20.25 5.12 20.25H14.37C14.78 20.25 15.12 20.59 15.12 21C15.12 21.41 14.79 21.75 14.38 21.75Z"
        fill="currentColor"
      />
      <path
        d="M9.74997 21.7501C9.68997 21.7501 9.62997 21.7401 9.56997 21.7301C9.16997 21.6301 8.91998 21.2201 9.01998 20.8201L13.52 2.8201C13.62 2.4201 14.02 2.1701 14.43 2.2701C14.83 2.3701 15.08 2.7801 14.98 3.1801L10.48 21.1801C10.39 21.5201 10.09 21.7501 9.74997 21.7501Z"
        fill="currentColor"
      />
    </svg>
  );
}
