import { DASHBOARD } from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import Chart from "./components/chart";

export default function DashboardPage() {
  const { selectChildren } = useLayoutContext();

  const ChildrenConversation = () => {
    switch (selectChildren) {
      case DASHBOARD:
        return <Chart />;

      default:
        return <Chart />;
    }
  };
  return (
    <div className="xl:px-4 pt-14 h-full bg-white overflow-auto">
      {ChildrenConversation()}
    </div>
  );
}
