
import { Campagne, PromotionalCampagne } from "../models/campagne-model";
import { ApiRoute } from "../utils/api-route";
import { headers } from "../utils/utils";
import { Catalog } from "../models/catalog-model";
import { ToastContainer, toast } from 'react-toastify';

export async function fetchAllCampagnes(): Promise<Campagne[] | []> {
    try {
      const response = await fetch(ApiRoute.campagnes , {
        headers: headers( )
      });
  
      if (response.status != 200) {
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const campagnes: Campagne[] = data.data;
  
      console.log("data", campagnes);
  
      return campagnes;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return [];
    }
}

export async function fetchAllPromotionalCampagnes(): Promise<PromotionalCampagne[] | []> {
    try {
      const response = await fetch(ApiRoute.promotional_campagnes , {
        headers: headers( )
      });
  
      if (response.status != 200) {
        //toast(response.status.toString());
        throw new Error(response.status.toString());
      }
  
      const data = await response.json();
      const campagnes: PromotionalCampagne[] = data.data;
  
      console.log("data", campagnes);
  
      return campagnes;
    } catch (e) {
      console.log("Une erreur c'est produite.", e);
  
      return [];
    }
}

export async function storePromotionalCampain(body :object): Promise<boolean>
{
  try {
    const response = await fetch(ApiRoute.promotional_campagnes + "/create" , {
      headers: headers(),
      method: "POST",
      body: JSON.stringify(body),
    });

    if (response.status != 200) {
      //toast(response.status.toString());
      throw new Error(response.status.toString());
    }

    return true;
  } catch (e) {
    console.log("Une erreur c'est produite.", e);

    return false;
  }
}

export async function uploadCatalogsFile(body: FormData): Promise<Catalog[] | []> {
  try {
    const response = await fetch(ApiRoute.catalogs + "/create" , {
      method: "POST",
      // headers: headers({ contentType : "multipart/form-data" }),
      body: body,
      redirect: "follow"
    });
    
    if (response.status != 200) {
      //toast(response.status.toString());
      throw new Error(response.status.toString());
    }

    const data = await response.json();
    const contacts: Catalog[] = data.data;

    return contacts;
  } catch (e) {
    //toast("Une erreur c'est produite.");
    console.log("Une erreur c'est produite.", e);

    return [];
  }
}


export async function updateCatalog(body: FormData, id: number): Promise<boolean> {
  try {
    const response = await fetch(ApiRoute.catalogs + "/update/" + id , {
      method: "POST",
      // headers: headers(),
      body: body,
      redirect: "follow"
    });
    
    if (response.status != 200) {
      //toast.error(response.status.toString());
      throw new Error(response.status.toString());
    }

    return true;
  } catch (e) {
    //toast.error("Une erreur c'est produite.");
    throw new Error("Une erreur c'est produite.");

    // return false;
  }
}
export async function deleteCatalog(id: number): Promise<boolean> {
  try {
    const response = await fetch(ApiRoute.catalogs + "/delete/" + id , {
      method: "DELETE",
      headers: headers(),
      redirect: "follow"
    });
    
    if (response.status != 200) {
      //toast.error(response.status.toString());
      throw new Error(response.status.toString());
    }

    return true;
  } catch (e) {
    //toast.error("Une erreur c'est produite.");
    throw new Error("Une erreur c'est produite.");

    // return false;
  }
}

export async function deletePromotionalCampain(id: number): Promise<boolean> {
  try {
    const response = await fetch(ApiRoute.promotional_campagnes + "/delete/" + id , {
      method: "DELETE",
      headers: headers(),
      redirect: "follow"
    });
    
    if (response.status != 200) {
      //toast.error(response.status.toString());
      throw new Error(response.status.toString());
    }

    return true;
  } catch (e) {
    //toast.error("Une erreur c'est produite.");
    throw new Error("Une erreur c'est produite.");

    // return false;
  }
}

export async function executePromotionalCampain(id: number, endpoint: string): Promise<boolean> {
  try {
    const response = await fetch(ApiRoute.promotional_campagnes + "/"+endpoint+"/" + id , {
      method: "POST",
      headers: headers(),
      redirect: "follow"
    });
    
    if (response.status != 200) {
      //toast.error(response.status.toString());
      throw new Error(response.status.toString());
    }

    return true;
  } catch (e) {
    //toast.error("Une erreur c'est produite.");
    throw new Error("Une erreur c'est produite.");

    // return false;
  }
}



