import React, { Fragment, useEffect, useRef, useState, FormEvent } from "react";
import Editor from "./editor";
import { AddIcon, SendIcon } from "./icons";
import Quill from "quill";
import {
  DocumentIcon,
  FolderIcon,
  PhotoIcon,
  PlusCircleIcon,
  PlusIcon,
  ShareIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import ChatMenu from "./menu-list";
import ActionsMenu from "./actions-menu";
import { sendTemplatedMessage, sendText } from "../services/chat-service";
import { ColorRing } from "react-loader-spinner";
import TurndownService from "turndown"
import { toast } from "react-toastify";
import { toastConfig } from "../utils/utils";
import { NotificationTemplate } from "../models/notification-template-model";
import { Spinner } from "./spinner";
import { ApiRoute } from "../utils/api-route";
import { AgentChatMessage, AgentConversation } from "../models/agent-chat-model";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { Auth, User } from "../models/auth-model";
import UploadFile from "./upload-file";
import FileImporter from "./file-importer";
// import turndownPluginGfm  from "turndown-plugin-gfm"

const Delta = Quill.import("delta");
interface InputFormProps {
  phone: string;
  templates?: NotificationTemplate[];
  setTemplateContent: (t: object) => void;
  setShowPreview: (e: boolean) => void;
  setTemplate: (t: NotificationTemplate) => void;
  template?: NotificationTemplate;
  chatType: string;
  setCaption: (caption: string) => void;
  setMessageToBeSentType: (mtbs: string) => void;
  textMessage: string;
  setTextMessage: (tm: string) => void;
  fileMessage: any|null;
  setFileMessage: (tm: any) => void;
  submitMessage: () => void;
  
}

export default function InputFormMessage({
  phone, 
  templates, 
  setTemplateContent, 
  setTemplate,
  template,
  setShowPreview,
  chatType,
  textMessage,
  setTextMessage,
  fileMessage,
  setFileMessage,
  submitMessage,
  setCaption,
  setMessageToBeSentType

}:InputFormProps) {
  const [loading, setLoading] = React.useState(false);
  const [messageType, setMessageType] = React.useState(0);
  const [selectedTemplate, setSelectedTemplate] = React.useState<number>();
  const [selectedFile, setSelectedFile] = React.useState();
  const [templateVariables, setTemplateVariables] = React.useState<string[]>([]);
  const turndownService = new TurndownService();

  function displayVariables() {
    let arr: number[] = [];
    if (typeof template != "undefined") {
      for (let index = 0; index < template.numberOfVariables; index++) {
        arr.push(index)
      }
    }
    return arr;
  }

  React.useEffect(() => {
    templates?.forEach(temp => {
      if (selectedTemplate == temp.id) {
        setTemplate(temp)
        setShowPreview(true)
        setTemplateVariables([])
        displayVariables()
      }
    });
  }, [selectedTemplate]);

  function handleFileChange(event: any) {
    setSelectedFile(event.target.files[0]);
  }
  function setVariableContent(value: string, variable: number) {
    let content = {
      value: value,
      variable: variable
    }

    setTemplateContent(content);
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    let p = phone!.replaceAll("@c.us","")
    if(p.indexOf("+") === -1) {
      p = "+" + p;
    }
    let formData = new FormData();
    formData.append("file", selectedFile!);
    formData.append("phone", p);
    for (let index = 0; index < templateVariables.length; index++) {
      formData.append(`variables[${index}]`, templateVariables[index]);
    }
    
    await sendTemplatedMessage(formData, template!.id).then((res) => {
      if (res === true) {
        toast.success("Message Template envoye avec success", toastConfig)
        setMessageType(0)
        setShowPreview(false)
      }
    })
    .catch(() => toast.error("Erreur Technique, veillez reessayer plus tard", toastConfig))
    .finally(() => setLoading(false))
  }

  function displayInputType() {
    switch (messageType) {
      case 0:
        return (
          <div className="search-chat flex flex-grow ">
              <Editor setText={(t: string) => {
                setMessageToBeSentType("text")
                setTextMessage(turndownService.turndown(t))
              }} loading={loading} />
              <div className="flex flex-col justify-end items-center  text-gray-400 p-2">
                <button onClick={() => {
                  setLoading(true);
                  submitMessage()
                  setTimeout(() => {
                    setLoading(false)
                  },500)
                  }} 
                  disabled={loading}
                >
                  <SendIcon className={"text-green-600 "+ (loading?"hidden":"")}  />
                  <ColorRing
                    visible={loading}
                    height="40"
                    width="40"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#32a852', '#32a852', '#32a852', '#32a852', '#32a852']}
                  />
                </button>
              </div>
            </div>
        );
        break;
      
        case 1:
          return (
            <div className="w-full">
            <form action="" onSubmit={handleSubmit}>
              <div className="float-end">
                <button 
                  onClick={() => {
                    setMessageType(0)
                    setShowPreview(false)
                  }}
                  className="rounded-circle my-2">
                  <XCircleIcon height={20} width={20} />
                </button>
              </div>
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <h3>
                    Template
                  </h3>
                </label>
                <div className="mt-2">
                  <select
                  onChange={(e) => {
                    setSelectedTemplate(parseInt(e.currentTarget.value))
                    
                  }} 
                  className="block w-full px-4 rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-green-600 focus:ring-green-600 sm:text-sm sm:leading-6">
                    <option>Choisir Template</option>
                    {templates?.map((template: NotificationTemplate) => {
                      return (
                        <option value={template.id}>{template.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <hr className="my-3" />
              <h3>Variables</h3>
              <div className="w-full grid grid-cols-2 my-3 space-x-2 gap-2" id="variables-wrapper">
                {displayVariables().map((v: number) => {
                  return (
                    <div className="w-full">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Variable {v + 1}
                      </label>
                      <div className="mt-2">
                      <input
                        type="text"
                        className="block w-full px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                        placeholder="Entrez valeur"
                        name={"variables["+v+"]"}
                        onInput={(e) => {
                          setVariableContent(e.currentTarget.value, v+1)
                          let vc = templateVariables;
                          vc[v] = e.currentTarget.value;
                          setTemplateVariables(vc);
                        }}
                        required
                      />
                      </div>
                    </div>
                  );
                })}
                
                
              </div>
              {template?.headerType !== "TEXT"?(
                <div className="w-full">
                  <hr className="my-3" />
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 capitalize"
                  >
                    {template?.headerType}
                  </label>
                  <div className="mt-2">
                  <input
                    type="file"
                    className="block w-full px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                    onInput={(e) => handleFileChange(e)}
                    required
                  />
                  </div>
                </div>
              ):(<></>)}
              <div className="text-center my-3">
                <button
                  type="submit"
                  className={"inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto "}
                  disabled={loading}
                >
                  <SendIcon className={"text-white "+ (loading?"hidden":"")} width={20} height={20}  /> 
                  {(loading? (
                    <Spinner size="sm" color="white" />
                  ):(""))}
                </button>
              </div>
            </form>
          </div>
          );
        break;

        case 2:
          setMessageToBeSentType("image")
          return (
            <div className="flex flex-grow">
              <div className="w-full">
                <FileImporter file={fileMessage} fileTypes={["jpg","png","jpeg","docx","pdf","CSV", "XLS", "XLSX"]} setFile={(file) => setFileMessage(file)} />
                <div className="w-full my-2">
                  <div className="mt-2">
                  <input
                    type="text"
                    className="block w-full px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                    placeholder="Legende"
                    onInput={(e) => {
                      setCaption(e.currentTarget.value)
                      
                    }}
                    required
                  />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-center  text-gray-400 p-2">
                <button onClick={() => {
                  setLoading(true);
                  submitMessage()
                  setTimeout(() => {
                    setLoading(false)
                    setFileMessage(undefined)
                    setCaption("")
                  },500)
                  }} 
                  disabled={loading}
                >
                  <SendIcon className={"text-green-600 "+ (loading?"hidden":"")}  />
                  <ColorRing
                    visible={loading}
                    height="40"
                    width="40"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#32a852', '#32a852', '#32a852', '#32a852', '#32a852']}
                  />
                </button>
              </div>
            </div>
          );
        case 3:
          setMessageToBeSentType("document")
          return (
            <div className="flex flex-grow">
              <div className="w-full">
                <FileImporter file={fileMessage} fileTypes={["jpg","png","jpeg","docx","pdf","CSV", "XLS", "XLSX"]} setFile={(file) => setFileMessage(file)} />
                <div className="w-full my-2">
                  <div className="mt-2">
                  <input
                    type="text"
                    className="block w-full px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                    placeholder="Legende"
                    onInput={(e) => {
                      setCaption(e.currentTarget.value)
                      
                    }}
                    required
                  />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-center  text-gray-400 p-2">
                <button onClick={() => {
                  setLoading(true);
                  submitMessage()
                  setTimeout(() => {
                    setLoading(false)
                    setFileMessage(undefined)
                    setCaption("")
                  },500)
                  }} 
                  disabled={loading}
                >
                  <SendIcon className={"text-green-600 "+ (loading?"hidden":"")}  />
                  <ColorRing
                    visible={loading}
                    height="40"
                    width="40"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#32a852', '#32a852', '#32a852', '#32a852', '#32a852']}
                  />
                </button>
              </div>
            </div>
          );
      default:
        setMessageType(0);
        break;
    }
  }

  return (
    <div className={"h-"+(messageType == 1?"98":"15")+" p-3 bg-white bottom-6 w-full sticky"}>
      <div className="flex items-end justify-end relative">
        <div className="p-2">
          <ActionsMenu
            icon={
              <AddIcon className="cursor-pointer h-6 w-6 text-black hover:text-green-600" />
            }
            setMessageType={(type) => setMessageType(type)}
          />
        </div>
        {displayInputType()}
      </div>
    </div>
  );
}
