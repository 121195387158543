import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  CogIcon,
  DocumentIcon,
  PencilIcon,
  PhotoIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { time } from "console";


interface ActionProps {
  icon: React.ReactNode;
  setMessageType?: (type: number) => void;
}

export default function ActionsMenu({ icon, setMessageType }: ActionProps) {
  
const menuChat = [

  {
    icon: CogIcon,
    name: "Message Template",
    borderBottom: true,
    action: () => {
      if (typeof setMessageType != "undefined") {
        setMessageType(1)
      }
    }
  },
  {
    icon: PhotoIcon,
    name: "Photos & vidéos",
    borderBottom: false,
    action: () => {
      if (typeof setMessageType != "undefined") {
        setMessageType(2)
      }
    }
  },
  {
    icon: DocumentIcon,
    name: "Document",
    borderBottom: false,
    action: () => {
      if (typeof setMessageType != "undefined") {
        setMessageType(3)
      }
    }
  },
  {
    icon: UserCircleIcon,
    name: "Contact",
    borderBottom: false,
    action: () => {}
  },
  {
    icon: ChartBarIcon,
    name: "Sondage",
    borderBottom: false,
    action: () => {}
  },
  {
    icon: BuildingStorefrontIcon,
    name: "Catalogue",
    borderBottom: false,
    action: () => {}
  },
];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          {icon}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute -right-32 -top-56 z-50 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            {menuChat.map((item, i) => {
              return (
                <Menu.Item key={i}>
                  {({ active }) => (
                    <>
                      <button
                        className={`${
                          active ? "bg-green-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                          item.action()
                        }}

                      >
                        <item.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                        {item.name}
                      </button>
                      {item.borderBottom?(<hr />):(<></>)} 
                    </>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
