import classNames from "classnames";
import {
  CONVERSATION_CONTACTS,
  CONVERSATION_DASHBOARD,
  CONVERSATION_HISTORY,
  CONVERSATION_MESSAGES,
} from "../../core/constants/layout";
import { useLayoutContext } from "../../core/context/layout-context";
import DashboardConversation from "./components/dashboard-conversation";
import HistoryConversation from "./components/history-conversation";
import MessageConversation from "./components/message-conversation";

export default function ConversationsPage() {
  const { selectChildren } = useLayoutContext();

  const ChildrenConversation = () => {
    switch (selectChildren) {
      case CONVERSATION_DASHBOARD:
        return <DashboardConversation />;
      case CONVERSATION_MESSAGES:
        return <MessageConversation />;
      case CONVERSATION_HISTORY:
        return <HistoryConversation />;
      case CONVERSATION_CONTACTS:
        return <MessageConversation showContactList={true} />;
      
      default:
        return <MessageConversation />;
    }
  };
  return (
    <div className={classNames("pt-12 h-screen bg-white")}>
      {ChildrenConversation()}
    </div>
  );
}
