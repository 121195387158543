import {
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import {
  CheckCircleIcon,
  CloudArrowDownIcon,
  DocumentArrowDownIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  InformationCircleIcon,
  PencilIcon,
  PlayIcon,
  TrashIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { Fragment } from "react";
import { Contact } from "../../../../models/contact-model";
import { ContactGroup } from "../../../../models/contact-group-model";
import { fetchContactGroups, fetchContacts, uploadContactsFile } from "../../../../services/contacts-service";
import { Spinner } from "../../../../components";
import { Menu, Transition } from "@headlessui/react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CAMPAGNE_CONTACT } from "../../../../core/constants/layout";
import { toastConfig } from "../../../../utils/utils";
import 'react-toastify/dist/ReactToastify.css'

interface StepFourProps {
  handleNext: () => void;
  setCampainContacts: (cs: Array<number>) => void;
  cancel: () => void;
}

const tabs = [
  {
    name: "Télécharger des contacts",
    href: "#",
    icon: DocumentArrowDownIcon,
    current: true,
    state: 0,
  },
  {
    name: "Sélectionner des contacts",
    href: "#",
    icon: UserGroupIcon,
    current: false,
    state: 1,
  },
];



export default function AddCampainDestinations({ handleNext, setCampainContacts, cancel }: StepFourProps) {
  const [statusTab, setStatusTab] = React.useState<number>(0);
  const [contacts, setContacts] = React.useState<Array<Contact>>([]);
  const [contactGroups, setContactGroups] = React.useState<Array<ContactGroup>>([]);
  const [selectedContacts, setSelectedContacts] = React.useState<Array<number>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [contactsFetched, setContactsFetched] = React.useState<boolean>(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [reloadContactList, setReloadContactList] = React.useState(true);
  
  const navigate = useNavigate();

  const secondTabs = [
    {
      name: "Destinataires ("+contacts.length+")",
      href: "#",
      current: true,
      state: 0,
    },
  
    // {
    //   name: "Groups (" + contactGroups.length + ")",
    //   href: "#",
    //   current: false,
    //   state: 2,
    // },
  ];
  async function getContacts() {
    setLoading(true);
    await fetchContacts().then((contacts: Contact[]) => {
      setContacts(contacts);
      fetchContactGroups().then((groups: ContactGroup[]) => {
        setContactGroups(groups);
        setContactsFetched(true);
        setLoading(false);
      })
    })
  }
  function addContact(id: number) {
    let sc = selectedContacts;
    sc.push(id);
    setSelectedContacts(sc);
  }
  function removeContact(id: number) {
    let sc = selectedContacts;
    let index = sc.indexOf(id);
    if (index > -1) {
      sc.splice(index, 1);
    }
    setSelectedContacts(sc);
  }
  async function importContacts() {
    setLoading(true);
    const formData = new FormData();
    formData.append('contacts', selectedFile!);
    var sc = selectedContacts;
    await uploadContactsFile(formData).then((contacts: Contact[]) => {
      if (contacts.length > 0) {
        toast.success("Operation Reussie",toastConfig);
        contacts.forEach(contact => {
          sc.push(contact.id);
        });
        setSelectedContacts(sc);
        setStatusTab(1);
        setReloadContactList(true)
      }
    }).catch((e)=> {
      toast.error(e,toastConfig);
    }).finally(() => {
      setLoading(false);
    });

  }

  function navigateToContactCreate() {
    navigate(`${CAMPAGNE_CONTACT}`)
    window.location.reload()
  }

  function handleFileChange(event: any) {
    setSelectedFile(event.target.files[0]);
  }

  const handleCheckboxChange = (contactId: number) => {
    const newSelectedContacts = [...selectedContacts]; // Create a copy
    const index = newSelectedContacts.indexOf(contactId);
    if (index === -1) {
      addContact(contactId)
    } else {
      removeContact(contactId);
    }
  };
  React.useEffect(() => {
    if (!contactsFetched || reloadContactList) {
      getContacts()
      setReloadContactList(false);
    }
  },[reloadContactList])
  return (
    <div className="border">
      <div className="flex flex-col divide-y">
        <div className=" px-4 py-4 gap-x-4 ">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)!.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="flex justify-center">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.name}
                      onClick={() => setStatusTab(tab.state)}
                      className={classNames(
                        statusTab === tab.state
                          ? "border-purple-500 text-purple-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      <tab.icon
                        className={classNames(
                          statusTab === tab.state
                            ? "text-purple-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
            {statusTab === 0 ? (
              <div className="mt-8">
                <div className="rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-blue-800">
                        Téléchargez en masse des contacts à partir de feuilles
                        de calcul CSV, Google Sheets ou Excel.
                      </h3>
                    </div>
                  </div>
                </div>
                {/* <h5 className="text-base font-semibold text-gray-600 mb-2 mt-4">
                  Importer un fichier à partir d'une URL
                </h5>
                <div className="flex items-center gap-2 ">
                  <input
                    type="text"
                    className="border rounded-sm h-10 w-full px-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                    placeholder="Entrez votre URL ici"
                  />
                </div> */}
                <hr className="my-4" />
                <h6 className={"text-sm font-normal text-"+(selectedFile === null? "purple": "green")+"-600 mb-2 mt-4"}>
                  Découvrez comment importer depuis Google Sheets
                </h6>
                <div className={"rounded-md border-4 border-dashed border-"+(selectedFile === null? "purple": "green")+"-500 bg-gray-50 p-4 shadow-md w-full"}>
                  <label
                    htmlFor="upload"
                    className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (selectedFile === null? "": "hidden")}
                  >
                    <CloudArrowDownIcon className="text-purple-600 h-12 w-12" />
                    <h5 className="text-sm font-semibold text-gray-600 ">
                      Glissez et déposez le fichier ou cliquez ici pour en
                      sélectionner un
                    </h5>
                    <span className="text-xs font-light text-gray-400">
                      Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                    </span>
                  </label>
                  <label
                    htmlFor="upload"
                    className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (selectedFile !== null? "": "hidden")}
                  >
                    <CheckCircleIcon className="text-green-600 h-12 w-12" />
                    <h5 className="text-sm font-semibold text-gray-600 ">
                      Glissez et déposez le fichier ou cliquez ici pour en
                      sélectionner un
                    </h5>
                    <span className="text-xs font-light text-gray-400">
                      Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                    </span>
                  </label>
                  
                  <input id="upload" type="file" className="hidden" onChange={handleFileChange} />
                </div>
                <span className="text-sm font-light text-gray-400">
                  Cliquez <strong><a href="/assets/template-import-contacts.xlsx" download target="_blank" className="text-purple-500 fw-bolder">ici</a></strong>  téléchargez une exemple
                </span>
                <div className="flex items-center justify-center px-5 py-3">
                  <button className={"text-white h-10 bg-green-600 hover:bg-green-500 px-4 py-2 rounded-sm text-xs " + (loading? "hidden":"")} onClick={(e) => importContacts()}>
                    Importer
                  </button>
                  <span className={!loading?"hidden":""}>
                    <Spinner size="sm" />
                  </span>
                </div>
              </div>
            ) : (
              <div className="mt-8">
                <div className="flex flex-col divide-y border">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher des contacts"
                    />
                    <div className="flex items-center gap-x-2">
                      <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm" onClick={navigateToContactCreate}>
                        Aujouter le contact
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              0 === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    {contacts.length == 0 && loading === false? (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucun contact disponible pour l'instant
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Les contacts WhatsApp n'ont pas encore été synchronisés,
                          vous pouvez réessayer dans quelques minutes ou créer des
                          contacts manuellement
                        </h5>
                        <div className="flex items-center gap-x-2">
                          <button onClick={navigateToContactCreate} className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm">
                            Ajouter un nouveau contact
                          </button>
                          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                            Recharger
                          </button>
                        </div>
                      </div>
                    ):(
                      <></>
                    )}
                    {loading === true ? (
                      <div className="flex flex-col justify-center items-center">
                        <Spinner size="lg"/>
                      </div>
                    ): (<></>)}

                    {loading === false && contacts.length > 0? (
                      <div className="px-4 sm:px-6 lg:px-8 h-full">
                      <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300 h-full">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                  >
                                    #
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                  >
                                    NOM
                                  </th>

                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    NUMERO
                                  </th>
                                  
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    EMAIL
                                  </th>
                                  
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    ADDRESSE
                                  </th>
                                  
                                  {/* <th
                                    scope="col"
                                    className="relative py-3 pl-3 pr-4 sm:pr-0"
                                  >Actions</th> */}
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white" >
                                { 
                                  contacts.map((contact: Contact) => (
                                  <tr 
                                  key={contact.id}
                                  >
                                    <td className="">
                                      <input type="checkbox" defaultChecked={selectedContacts.includes(contact.id)? true : undefined} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" id="" onChange={(e) => {
                                        handleCheckboxChange(contact.id)
                                      }} />
                                    </td>
                                    <td className="whitespace-nowrap flex flex-col gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      <span className="text-purple-600">
                                        {contact?.name ?? "-"}
                                      </span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {contact.phone}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {contact?.email ?? "-"}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-uppercase">
                                      {contact?.address ?? "-"}
                                    </td>
                                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                      <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                      >
                                        <div>
                                          <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                            <EllipsisVerticalIcon className="h-6 w-6" />
                                          </Menu.Button>
                                        </div>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                            <div className="px-1 py-1 ">
                                              <div className="px-1 py-1">
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <button
                                                      className={`${
                                                        active
                                                          ? "bg-purple-500 text-white"
                                                          : "text-gray-900"
                                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                      {active ? (
                                                        <PencilIcon
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      ) : (
                                                        <PencilIcon
                                                          className="mr-2 h-5 w-5"
                                                          aria-hidden="true"
                                                        />
                                                      )}
                                                      Modifier
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              </div>
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <button
                                                    className={`${
                                                      active
                                                        ? "bg-purple-500 text-white"
                                                        : "text-gray-900"
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                  >
                                                    {active ? (
                                                      <TrashIcon
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    ) : (
                                                      <TrashIcon
                                                        className="mr-2 h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    )}
                                                    Supprimer
                                                  </button>
                                                )}
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    ):(<></>)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between px-5 py-3">
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          /> */}
          {/* <ToastContainer /> */}
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={() => cancel}>
            Annuler
          </button>
          <button
            onClick={() => {
              if (selectedContacts.length !== 0) {
                setCampainContacts(selectedContacts);
                handleNext()
              }
            }}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
}
