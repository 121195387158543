import classNames from "classnames";

type SizeType = "xs" | "sm" | "md" | "lg" | "xl";

interface SpinnerProps {
  size: SizeType;
  color?: string;
}

const sizes = {
  xs: ["h-4 w-4"],
  sm: ["h-6 w-6"],
  md: ["h-8 w-8"],
  lg: ["h-10 w-10"],
  xl: ["h-12 w-12"],
};
export function Spinner({ size, color }: SpinnerProps) {
  return (
    <div
      className={classNames(
        sizes[size],
        "inline-block animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-"+(typeof color == "undefined" || color === ""?"blue":color)+"-600"
      )}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
}
