import { CheckCircleIcon, CloudArrowDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import "react-quill/dist/quill.snow.css"; // Importe les styles par défaut de ReactQuill

interface FileImporterProps {
  setFile: (file: any) => void;
  file: any|null;
  fileTypes: Array<string>;
}
const FileImporter = ({
  setFile,
  file,
  fileTypes
}: FileImporterProps) => {

  const [fileName, setFileName] = React.useState("");
  function handleFileChange(event: any) {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  }

  return (
    <>
      <div className={"rounded-md border-4 border-dashed border-"+(typeof file === "undefined" || file === null? "purple": "green")+"-500 bg-gray-50 p-4 shadow-md w-full"}>
        <label
          htmlFor="upload"
          className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (typeof file === "undefined" || file === null? "": "hidden")}
        >
          <CloudArrowDownIcon className="text-purple-600 h-12 w-12" />
          <h5 className="text-sm font-semibold text-gray-600 ">
            Glissez et déposez le fichier ou cliquez ici pour en
            sélectionner un
          </h5>
          <span className="text-xs font-light text-gray-400">
            Seuls les fichiers {fileTypes?.toString()} sont acceptés (jusqu'à 5 Mo)
          </span>
        </label>
        <label
          htmlFor="upload"
          className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (file !== null && typeof file !== "undefined" ? "": "hidden")}
        >
          <CheckCircleIcon className="text-green-600 h-12 w-12" />
          <h5 className="text-sm font-semibold text-gray-600 ">
           {fileName}
          </h5>
          <span className="text-xs font-light text-gray-400">
            Seuls les fichiers {fileTypes?.toString()} sont acceptés (jusqu'à 5 Mo)
          </span>
        </label>
        
        <input id="upload" type="file" className="hidden" onChange={handleFileChange} />
      </div>
    </>
  );
};

export default FileImporter;
