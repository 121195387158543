
import {
  CloudArrowDownIcon,
  DocumentArrowDownIcon,
  InformationCircleIcon,
  CameraIcon,
  CheckCircleIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  PencilIcon
  
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { Fragment } from "react";
import { Spinner } from "../../../../components";
import { Menu, Transition } from "@headlessui/react";
import { Catalog } from "../../../../models/catalog-model";
import { fetchCatalogs } from "../../../../services/contacts-service";
import { useNavigate } from "react-router-dom";
import { CAMPAGNE_MEDIA } from "../../../../core/constants/layout";
import { toast } from "react-toastify";
import { uploadCatalogsFile } from "../../../../services/campagnes-service";
import 'react-toastify/dist/ReactToastify.css';
import EditCatalogDialog from "./catalog/edit-catalog-dialog";
import DeleteCatalogDialog from "./catalog/delete-catalog-dialog";
import { toastConfig } from "../../../../utils/utils";


interface StepFourProps {
  handleNext?: () => void;
  setCampainCatalog?: (cs: Array<number>) => void;
  cancel: () => void;
}



export default function AddCampainCatalog({ handleNext, setCampainCatalog, cancel }: StepFourProps) {
  const [statusTab, setStatusTab] = React.useState<number>(0);
  const [catalogs, setCatalogs] = React.useState<Array<Catalog>>([]);
  const [selectedCatalogs, setSelectedCatalogs] = React.useState<Array<number>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [catalogsFetched, setCatalogsFetched] = React.useState<boolean>(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [reloadCatalogList, setReloadCatalogList] = React.useState(true);
  const [prix, setPrix] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<Catalog|null>(null);
  const navigate = useNavigate();

  const tabs = [
    {
      name: "Télécharger des Images/Articles",
      href: "#",
      icon: DocumentArrowDownIcon,
      current: true,
      state: 0,
    },
    {
      name: typeof setCampainCatalog == "undefined"?"Catalogue Des Articles/Images": "Sélectionner des Images/Articles",
      href: "#",
      icon: CameraIcon,
      current: false,
      state: 1,
    },
  ];
  async function getCatalogs() {
    setLoading(true);
    await fetchCatalogs().then((c: Catalog[]) => {
      setCatalogs(c);
      setCatalogsFetched(true);
      setLoading(false);
    })
  }
  
  function addCatalog(id: number) {
    let sc = selectedCatalogs;
    sc.push(id);
    setSelectedCatalogs(sc);
  }
  function removeCatalog(id: number) {
    let sc = selectedCatalogs;
    let index = sc.indexOf(id);
    if (index > -1) {
      sc.splice(index,1);
    }
    setSelectedCatalogs(sc);
  }
  
  async function importCatalogs() {
    setLoading(true);
    const formData = new FormData();
    console.log(selectedFile);
    formData.append('catalog[0][media]', selectedFile!);
    formData.append('catalog[0][prix]', "" + prix);
    formData.append('catalog[0][description]', description);
    var sc = selectedCatalogs;
    await uploadCatalogsFile(formData).then((catalogs: Catalog[]) => {
      if (catalogs.length > 0) {
        toast.success('Operation Reussie', toastConfig);
        catalogs.forEach(catalog => {
          sc.push(catalog.id);
        });
        setSelectedCatalogs(sc);
        setStatusTab(1);
        setReloadCatalogList(true)
      }
    }).catch((e)=> {
      toast.error("Une erreur technique s'est produite.. veillez verifier votre formulaire", toastConfig);
    }).finally(() => {
      setLoading(false);
    });

  }

  const handleCheckboxChange = (catalogId: number) => {
    const newSelectedCatalogs = [...selectedCatalogs]; // Create a copy
    const index = newSelectedCatalogs.indexOf(catalogId);
    console.log(catalogId)
    if (index === -1) {
      addCatalog(catalogId)
    } else {
      removeCatalog(catalogId);
    }
    console.log(selectedCatalogs);
  };
  
  function navigateToCatalogCreate() {
    navigate(`${CAMPAGNE_MEDIA}`)
    window.location.reload()
  }

  function handleFileChange(event: any) {
    setSelectedFile(event.target.files[0]);
  }
  React.useEffect(() => {
    if (!catalogsFetched || reloadCatalogList) {
      getCatalogs();
      setReloadCatalogList(false)
    }
  },[reloadCatalogList])
  return (
    <div className="border">
      <div className="flex flex-col divide-y">
        <div className=" px-4 py-4 gap-x-4 ">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)!.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="flex justify-center">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.name}
                      onClick={() => setStatusTab(tab.state)}
                      className={classNames(
                        statusTab === tab.state
                          ? "border-purple-500 text-purple-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      <tab.icon
                        className={classNames(
                          statusTab === tab.state
                            ? "text-purple-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
            {statusTab === 0 ? (
              <div className="mt-8">
                <div className="rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-800"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-blue-800">
                        Téléchargez des images JPG, PNG ou JPEG de vos articles.
                      </h3>
                    </div>
                  </div>
                </div>
                <h5 className="text-base font-semibold text-gray-600 mb-2 mt-4">
                  Informations de l'article
                </h5>
                <div className="flex items-center gap-2 ">
                  <input
                    type="text"
                    className="border rounded-sm h-10 w-full px-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                    placeholder="Entrez Le Nom/Courte Description/Intitule"
                    onInput={(e) => {
                      setDescription(e.currentTarget.value)
                    }}
                  />
                  <input
                    type="number"
                    className="border rounded-sm h-10 w-full px-2 placeholder:text-sm font-light text-gray-600 placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                    placeholder="Entrez le Prix"
                    onInput={(e) => {
                      setPrix(parseInt(e.currentTarget.value))
                    }}
                  />
                  
                </div>
                <h6 className={"text-sm font-normal text-"+(selectedFile === null? "purple": "green")+"-600 mb-2 mt-4"}>
                  Découvrez comment importer depuis Google Sheets
                </h6>
                <div className={"rounded-md border-4 border-dashed border-"+(selectedFile === null? "purple": "green")+"-500 bg-gray-50 p-4 shadow-md w-full"}>
                  <label
                    htmlFor="upload"
                    className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (selectedFile === null? "": "hidden")}
                  >
                    <CloudArrowDownIcon className="text-purple-600 h-12 w-12" />
                    <h5 className="text-sm font-semibold text-gray-600 ">
                      Glissez et déposez le fichier ou cliquez ici pour en
                      sélectionner un
                    </h5>
                    <span className="text-xs font-light text-gray-400">
                      Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                    </span>
                  </label>
                  <label
                    htmlFor="upload"
                    className={"flex flex-col py-10 items-center gap-2 cursor-pointer " + (selectedFile !== null? "": "hidden")}
                  >
                    <CheckCircleIcon className="text-green-600 h-12 w-12" />
                    <h5 className="text-sm font-semibold text-gray-600 ">
                      Glissez et déposez le fichier ou cliquez ici pour en
                      sélectionner un
                    </h5>
                    <span className="text-xs font-light text-gray-400">
                      Seuls les fichiers CSV, XLS ou XLSX sont acceptés (jusqu'à 5 Mo)
                    </span>
                  </label>
                  
                  <input id="upload" type="file" className="hidden" onChange={handleFileChange} />
                </div>
                {/* <span className="text-xs font-light text-gray-400">
                  Téléchargez une exemple de fichier CSV ou un exemple de fichier Excel
                </span> */}
                <div className="flex items-center justify-center px-5 py-3">
                  
                  {loading === true ? (
                        <Spinner size="sm"/>
                    ): (<>
                    <button disabled={loading} className="text-white h-10 bg-green-600 hover:bg-green-500 px-4 py-2 rounded-sm text-xs" onClick={(e) => importCatalogs()}>
                    Importer
                  </button>
                  </>)}
                </div>
              </div>
            ) : (
              <div className="mt-8">
                <div className="flex flex-col divide-y border">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher des articles/images"
                    />
                    <div className="flex items-center gap-x-2">
                      <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm" onClick={() => setStatusTab(0)}>
                        Ajouter Article/Image
                      </button>
                    </div>
                  </div>
                  <div>
                    {catalogs.length == 0 && loading === false? (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucune Image disponible pour l'instant
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Veillez Telecharger des images pour joinde a votre campagne
                        </h5>
                        {/* <div className="flex items-center gap-x-2">
                          <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm">
                            Ajouter un nouveau catalog
                          </button>
                          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                            Recharger
                          </button>
                        </div> */}
                      </div>
                    ):(
                      <></>
                    )}
                    {loading === true ? (
                      <div className="flex flex-col justify-center items-center">
                        <Spinner size="lg"/>
                      </div>
                    ): (<></>)}

                    {loading === false && catalogs.length > 0? (
                      <>
                        {catalogs.map((catalog: Catalog) => (
                            <div
                              onClick={(e) => {
                                if (typeof setCampainCatalog != "undefined") {
                                  document.getElementById("clog-"+catalog.id)?.click()
                                  setTimeout(() => {
                                    if (selectedCatalogs.includes(catalog.id)) {
                                      document.getElementById("clog-container-"+catalog.id)?.classList.add("bg-gray-300")
                                    } else {
                                      document.getElementById("clog-container-"+catalog.id)?.classList.remove("bg-gray-300")
                                    }
                                  }, 500);
                                }
                              }}
                              className={" "}
                              id={"clog-container-"+ catalog.id}
                            >
                              <div className="grid grid-cols-12">
                                <div className="col-span-11 flex flex-row items-center gap-x-4 border-bottom-1 py-4 px-8 cursor-pointer mt-2">
                                  <div className="flex align-end">
                                      <input type="checkbox" 
                                      className={"w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 hidden "} 
                                      id={"clog-"+catalog.id} 
                                      onChange={(e) => {
                                          handleCheckboxChange(catalog.id)
                                          // if (e.currentTarget.checked) {
                                          //   addCatalog(catalog.id)
                                          // } else {
                                          //   removeCatalog(catalog.id);
                                          // }
                                        }} />
                                  </div>
                                  <img
                                    src={catalog.url}
                                    className="w-16 h-16"
                                    alt=""
                                  />
                                  <div className="flex flex-col items-start gap-2">
                                    <h3 className="text-sm font-medium">{catalog.name}</h3>
                                    <span className={"px-2 py-1 bg-green-600 text-white text-[10px] rounded-xl"}>
                                      XAF {catalog?.prix}
                                    </span>
                                    
                                    <span className="text-xs font-normal text-gray-500">
                                      {catalog?.description}
                                    </span>
                                  </div>

                                </div>
                                <div className={"justify-self-center content-center " + (typeof setCampainCatalog == "undefined"?"":"hidden")}>
                                <Menu
                                  as="div"
                                  className="relative inline-block text-left"
                                >
                                  <div>
                                    <Menu.Button className="inline-flex w-full justify-center  px-4 py-2 text-sm font-medium text-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                      <EllipsisVerticalIcon className="h-6 w-6" />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                      <div className="px-1 py-1 ">
                                        <div className="px-1 py-1">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                onClick={() => {
                                                  setSelectedImage(catalog);
                                                  setUpdateOpen(true)
                                                }}
                                                className={`${
                                                  active
                                                    ? "bg-purple-500 text-white"
                                                    : "text-gray-900"
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                              >
                                                {active ? (
                                                  <PencilIcon
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                ) : (
                                                  <PencilIcon
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                )}
                                                Modifier
                                              </button>
                                            )}
                                          </Menu.Item>
                                        </div>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                            onClick={() => {
                                              setSelectedImage(catalog);
                                              setDeleteOpen(true)
                                            }}
                                              className={`${
                                                active
                                                  ? "bg-purple-500 text-white"
                                                  : "text-gray-900"
                                              } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            >
                                              {active ? (
                                                <TrashIcon
                                                  className="mr-2 h-5 w-5 text-red-500"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <TrashIcon
                                                  className="mr-2 h-5 w-5 text-red-500"
                                                  aria-hidden="true"
                                                />
                                              )}
                                              Supprimer
                                            </button>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                                </div>
                              </div>
                              <hr />
                          </div>
                        ))}
                      </>
                              
                    ):(<></>)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"flex items-center justify-between px-5 py-3 " + (typeof setCampainCatalog == "undefined"? "hidden": "")}>
          <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm" onClick={cancel}>
            Annuler
          </button>
          <button
            onClick={() => {
              if (typeof setCampainCatalog != "undefined") {
                setCampainCatalog(selectedCatalogs)
              }
              if (typeof handleNext != "undefined") {
                handleNext()
              }
            }}
            className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-sm text-sm"
          >
            Continuer
          </button>
        </div>
      </div>
      <EditCatalogDialog open={updateOpen} setOpen={() => setUpdateOpen(!updateOpen)} setReload={(r: boolean) => setReloadCatalogList(r)} catalog={selectedImage!} />
      <DeleteCatalogDialog open={deleteOpen} setOpen={() => setDeleteOpen(!deleteOpen)} setReload={(r: boolean) => setReloadCatalogList(r)} catalog={selectedImage!} />
    </div>
  );
}
